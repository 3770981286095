import React, { useEffect, useRef, useState } from 'react'

// Styles
import styles from './index.module.scss'

// Components
import { Tabs } from '../../Tabs/Tabs'
import { Loader } from '../../Loader'
import { CompetitionRanksGeneral } from './CompetitionRanksGeneral'
import { CompetitionRanksItem } from './CompetitionRanksItem'
import { Select } from '../../Select'
import { fetchData } from '../../../utils/fetchData'

export const CompetitionRanks = ({id, type, competitionType, withGroup, rounds}) => {
    const [content, setContent] = useState(rounds.length > 0 ? 'general' : 'goals')
    const [loading, setLoading] = useState(true)
    const [filterItems, setFilterItems] = useState([])
    const [filterValue, setFilterValue] = useState(rounds && rounds.length > 0 && rounds[0].value)

    const [ranking, setRanking] = useState([])
    const [stats, setStats] = useState({});

    const stat_keys = ['rating', 'goals', 'assists', 'passes', 'interceptions', 'tackles', 'saves', 'clean_sheet'];
    const tabsData = useRef([{
        id: 1,
        name: 'general',
        value: 'general',
        hide: rounds.length === 0,
    }, ...stat_keys.map((stat, i) => ({ id: i + 2, name: stat, value: stat }))])

    useEffect(() => {
        if (withGroup && rounds) {
            const tempItems = []

            rounds.forEach((round, idx) => {
                tempItems.push({
                    id: idx,
                    name: round.name,
                    value: round.value,
                })
            })

            setFilterItems([...tempItems])
        }
    }, [rounds])

    const getRanking = async () => {
        const res = await fetchData(`/api/v4/competitions/${type}/${competitionType}/${id}/ranking`)
        setRanking([...res.ranking])
    }

    const getRankingWithGroup = async () => {
        const res = await fetchData(`/api/v4/competitions/${type}/${competitionType}/${id}/ranking?group=${filterValue}`)
        setRanking([...res.ranking])
    }

    const getStatItem = async (item) => {
        const res = await fetchData(`/api/v4/competitions/${type}/${competitionType}/${id}/stats/ranking?stat=${item}`)
        setStats((prev) => ({...prev, [item]: res.ranking}))
    }

    useEffect(() => {
        if (withGroup) {
            (async () => {
                setLoading(true)
                await getRankingWithGroup()
                setLoading(false)
            })()
        }
    }, [filterValue])

    useEffect(() => {
        (async () => {
            await Promise.all([
                !withGroup && getRanking(),
                withGroup && getRankingWithGroup(),
                type === 'squad' && stat_keys.map((stat) => getStatItem(stat)),
            ])
            setLoading(false)
        })()
    }, [])

    return (
        <div className={styles.ranks}>
            {
                type === 'squad' && (
                    <div className={styles.ranksTabs}>
                        <Tabs initTabs={tabsData.current} setActiveTab={setContent} high />
                    </div>
                )
            }
            {
                withGroup && filterItems.length > 0 && content === 'general' && (
                    <div className={styles.ranksFilterWrap}>
                        <Select values={filterItems} setNewValue={setFilterValue} />
                    </div>
                )
            }
            {
                !loading ? (
                    <div className={styles.ranksContent}>
                        {
                            content === 'general' && (
                                ranking.length > 0 ? ranking.map((item, idx) => (
                                    <CompetitionRanksGeneral general={true} data={item} key={item.id || idx} idx={idx} />
                                )) : <div className={'empty-block'}>Vide</div>
                            )
                        }
                        {stat_keys.map((stat) => (
                            content === stat && (
                                stats[stat] && stats[stat].length > 0 ? stats[stat].map((item, idx) => (
                                <CompetitionRanksItem data={item} key={item.id || idx} idx={idx} title={stat} />
                            )) : <div className={'empty-block'}>Vide</div>
                        )))}
                    </div>
                ) : <Loader />
            }
        </div>
    )
}
