import { useState } from 'react'
import axios from "axios"

// Utils
import { deleteCookie, setCookie } from '../utils/cookie'
import { useDispatch } from 'react-redux'
import { signIn, signOut } from '../../store/slices/userSlice'
import api from '../utils/api'

export const useAuth = () => {
    const dispatch = useDispatch()
    const [isChecked, setIsChecked] = useState(false)

    const checkIsAuth = async () => {
        try {
            const credentials = await api.getAccessToken();
            axios.defaults.headers.common.Authorization = `Bearer ${credentials.accessToken}`;
            setCookie("refresh_token", credentials.refreshToken);
            setCookie("csrf_token", credentials.csrfToken);
            dispatch(signIn(credentials.accessToken));
        } catch (e) {
            axios.defaults.headers.common.Authorization = "";
            deleteCookie("csrf_token");
            deleteCookie("refresh_token");
            dispatch(signOut());
        } finally {
            setIsChecked(true);
        }
    }

    return {checkIsAuth, isChecked}
}
