import React, { useEffect, useState } from 'react'

// Styles
import styles from './index.module.scss'

//image
import footFeild from '../../../assets/images/footField.svg'

export const FootballField = ({initValue, values, setNewValue}) => {
    const [value, setValue] = useState(initValue)

    useEffect(()=>{
        if(value != initValue){
            setNewValue(value)
        }
    }, [value])
    return (
        <div className={styles.imgWrapper} style={{backgroundImage: `url(${footFeild})`}}>
            <div className={styles.wrapper}>
            {values.map((item) => (
                <div key={item.line} className={styles.line} style={{justifyContent: item.positions[0].css_class}}>
                    {item.positions.map((pos) => (
                        <div key={pos.id} className={styles.position} onClick={() => setValue(pos.id)}>
                            <span>{pos.acronym}</span>
                            <div className={styles.positionBar} style={{backgroundColor: pos.id === value ? pos.color : 'grey'}}/>
                        </div>
                    ))}
                </div>
            ))}
            </div>
        </div>

    )
}
