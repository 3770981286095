import cn from 'classnames'
import React from 'react'
import {Link} from 'react-router-dom'
import {useMobile} from '../../hooks/useMobile'
import {SvgSprite} from '../../ui/SvgSprite/SvgSprite'
// Styles
import styles from './index.module.scss'
import logo from '../../../assets/images/logo.png'

export const Cgu = () => {
    const isTablet = useMobile(1300)
    return (
        <div className={styles.pageWrapper}>
            <div className={cn({
                [styles.pageWrapperContent]: !isTablet,
                [styles.pageWrapperMobile]: isTablet
            })}>
                {!isTablet && (
                    <div className={styles.img}>
                        <img
                            className={styles.banner}
                            src={logo}
                            alt="Ifc Banner"
                        />
                    </div>
                )}
                <div className={cn(styles.text, {
                    [styles.textMobile]: isTablet,
                })}>
                    <div className={styles.header}>
                        <Link to={'/auth/login'} className={styles.matchArrowLink}>
                            <SvgSprite spriteID={'leftArrow'}/>
                        </Link>
                        Terms of Service
                    </div>
                    <div className={styles.body}>
                        <div className={styles.containerContent}>
                            <p className={styles.parag}>Last udpated: April 10, 2023</p>
                            <h1 className={styles.headerI}>
                                1. Introduction; Your Agreement to these Terms of Service
                            </h1>
                            <p className={styles.parag}>
                                PLEASE READ THESE TERMS OF SERVICE CAREFULLY. THIS IS A BINDING
                                CONTRACT. Welcome to the services operated by THE IFC (collectively with
                                its affiliates, “THE IFC” or “We”) consisting of the website available
                                at{" "}
                                <a
                                    className={styles.a}
                                    href="https://www.the-ifc.fr"
                                >
                                    https://www.the-ifc.fr
                                </a>
                                , and its network of websites, software applications, or any other
                                products or services offered by THE IFC (the “THE IFC Services”). Other
                                services offered by THE IFC may be subject to separate terms.
                            </p>
                            <p className={styles.parag}>
                                When using the THE IFC Services, you will be subject to guidelines or
                                rules that are posted on the THE IFC Services, made available to you, or
                                disclosed to you in connection with specific services and features. THE
                                IFC may also offer certain paid services, which are subject to THE IFC{" "}
                                <Link to={'/terms-of-sale'} className={styles.a}>
                                    Terms of Sale
                                </Link>{" "}
                                as well as any additional terms or conditions that are disclosed to you
                                in connection with such services. All such terms and guidelines (the
                                “Guidelines”) are incorporated into these Terms of Service by reference.
                            </p>
                            <p className={styles.parag}>
                                The Terms of Service apply whether you are a user that registers an
                                account with the THE IFC Services or an unregistered user. You agree
                                that by clicking “Sign Up” or otherwise registering, downloading,
                                accessing, or using the THE IFC Services, you are entering into a
                                legally binding agreement between you and THE IFC regarding your use of
                                the THE IFC Services. You acknowledge that you have read, understood,
                                and agree to be bound by these Terms of Service. If you do not agree to
                                these Terms of Service, do not access or otherwise use any of the THE
                                IFC Services.
                            </p>
                            <p className={styles.parag}>
                                When using THE IFC or opening an account with THE IFC on behalf of a
                                company, entity, or organization (collectively, “Subscribing
                                Organization”), you represent and warrant that you: (i) are an
                                authorized representative of that Subscribing Organization with the
                                authority to bind that organization to these Terms of Service and grant
                                the licenses set forth herein; and (ii) agree to these Terms of Service
                                on behalf of such Subscribing Organization.
                            </p>

                            <h1 className={styles.headerI}>
                                2. Use of THE IFC by Minors and Blocked Persons
                            </h1>
                            <p className={styles.parag}>
                                The THE IFC Services are not available to persons under the age of 13.
                                If you are between the ages of 13 and the age of legal majority in your
                                jurisdiction of residence, you may only use the THE IFC Services under
                                the supervision of a parent or legal guardian who agrees to be bound by
                                these Terms of Service.
                            </p>
                            <p className={styles.parag}>
                                The THE IFC Services are also not available to any users previously
                                removed from the THE IFC Services by THE IFC or to any persons barred
                                from receiving them under the applicable laws in any other jurisdiction.
                            </p>
                            <p className={styles.parag}>
                                BY DOWNLOADING, INSTALLING, OR OTHERWISE USING THE THE IFC SERVICES, YOU
                                REPRESENT THAT YOU ARE AT LEAST 13 YEARS OF AGE, THAT YOUR PARENT OR
                                LEGAL GUARDIAN AGREES TO BE BOUND BY THESE TERMS OF SERVICE IF YOU ARE
                                BETWEEN 13 AND THE AGE OF LEGAL MAJORITY IN YOUR JURISDICTION OF
                                RESIDENCE, AND THAT YOU HAVE NOT BEEN PREVIOUSLY REMOVED FROM AND ARE
                                NOT PROHIBITED FROM RECEIVING THE THE IFC SERVICES.
                            </p>

                            <h1 className={styles.headerI}>3. Privacy Notice</h1>
                            <p className={styles.parag}>
                                Your privacy is important to THE IFC. Please see our{" "}
                                <Link to={'/privacy-policy'} className={styles.a}>
                                    Privacy Policy
                                </Link>{" "}
                                for information relating to how we collect, use, and disclose your
                                personal information
                                {/*et nos Choix de confidentialité pour obtenir*/}
                                {/*des informations sur la façon dont vous pouvez gérer votre vie privée*/}
                                {/*en ligne lorsque vous utilisez les Services THE IFC.*/}
                            </p>

                            <h1 className={styles.headerI}>4. Account and password</h1>
                            <p className={styles.parag}>
                                In order to open an account, you will be asked to provide us with
                                certain information such as an account name and password.
                            </p>
                            <p className={styles.parag}>
                                You are solely responsible for maintaining the confidentiality of your
                                account, your password and for restricting access to your computer. If
                                you permit others to use your account credentials, you agree to these
                                Terms of Service on behalf of all other persons who use the Services
                                under your account or password, and you are responsible for all
                                activities that occur under your account or password. Please make sure
                                the information you provide to THE IFC upon registration and at all
                                other times is true, accurate, current, and complete to the best of your
                                knowledge.
                            </p>
                            <p className={styles.parag}>
                                Unless expressly permitted in writing by THE IFC, you may not sell,
                                rent, lease, share, or provide access to your account to anyone else,
                                including without limitation, charging anyone for access to
                                administrative rights on your account. THE IFC reserves all available
                                legal rights and remedies to prevent unauthorized use of the THE IFC
                                Services, including, but not limited to, technological barriers, IP
                                mapping, and, in serious cases, directly contacting your Internet
                                Service Provider (ISP) regarding such unauthorized use.
                            </p>

                            <h1 className={styles.headerI}>
                                5. Use of Devices and Services
                            </h1>
                            <p className={styles.parag}>
                                Access to the THE IFC Services may require the use of your personal
                                computer or mobile device, as well as communications with or use of
                                space on such devices. You are responsible for any Internet connection
                                or mobile fees and charges that you incur when accessing the THE IFC
                                Services.
                            </p>

                            <h1 className={styles.headerI}>
                                6. Modification of these Terms of Service
                            </h1>
                            <p className={styles.parag}>
                                THE IFC may amend any of the terms of these Terms of Service by posting
                                the amended terms. Your continued use of the THE IFC Services after the
                                effective date of the revised Terms of Service constitutes your
                                acceptance of the terms.
                            </p>
                            <p className={styles.parag}>
                                For residents of the Republic of Korea, THE IFC will provide reasonable
                                prior notice regarding any material amendments to its Terms of Service.
                                All amendments shall become effective no sooner than 30 calendar days
                                after posting; provided that any amendment regarding newly available
                                features of the Service, features of the Service that are beneficial to
                                the user, or changes made for legal reasons may become effective
                                immediately.
                            </p>

                            <h1 className={styles.headerI}>7. License</h1>
                            <p className={styles.parag}>
                                The THE IFC Services are owned and operated by THE IFC. Unless otherwise
                                indicated, all content, information, and other materials on the THE IFC
                                Services (excluding User Content, set out in Section 8 below),
                                including, without limitation, THE IFC’s trademarks and logos, the
                                visual interfaces, graphics, design, compilation, information, software,
                                computer code (including source code or object code), services, text,
                                pictures, information, data, sound files, other files, and the selection
                                and arrangement thereof (collectively, the “Materials”) are protected by
                                relevant intellectual property and proprietary rights and laws. All
                                Materials are the property of THE IFC or its subsidiaries or affiliated
                                companies and/or third-party licensors. Unless otherwise expressly
                                stated in writing by THE IFC, by agreeing to these Terms of Service you
                                are granted a limited, non-sublicensable license (i.e., a personal and
                                limited right) to access and use the THE IFC Services for your personal
                                use or internal business use only.
                            </p>
                            <p className={styles.parag}>
                                THE IFC reserves all rights not expressly granted in these Terms of
                                Service. This license is subject to these Terms of Service and does not
                                permit you to engage in any of the following: (a) resale or commercial
                                use of the THE IFC Services or the Materials; (b) distribution, public
                                performance or public display of any Materials; (c) modifying or
                                otherwise making any derivative uses of the THE IFC Services or the
                                Materials, or any portion of them; (d) use of any data mining, robots,
                                or similar data gathering or extraction methods; (e) downloading (except
                                page caching) of any portion of the THE IFC Services, the Materials, or
                                any information contained in them, except as expressly permitted on the
                                THE IFC Services; or (f) any use of the THE IFC Services or the
                                Materials except for their intended purposes. Any use of the THE IFC
                                Services or the Materials except as specifically authorized in these
                                Terms of Service, without the prior written permission of THE IFC, is
                                strictly prohibited and may violate intellectual property rights or
                                other laws. Unless explicitly stated in these Terms of Service, nothing
                                in them shall be interpreted as conferring any license to intellectual
                                property rights, whether by estoppel, implication, or other legal
                                principles. THE IFC can terminate this license as set out in Section 12.
                            </p>

                            <h1 className={styles.headerI}>8. User Content</h1>
                            <p className={styles.parag}>
                                THE IFC allows you to use services, such as chat, forum postings, and
                                voice interactive services; and to participate in other activities in
                                which you may create, post, transmit, perform, or store content,
                                messages, text, sound, images, or other data or materials on the THE IFC
                                Services (“User Content”).
                            </p>
                            <p className={styles.parag}>
                                For residents of the Republic of Korea, distribution of pre-recorded
                                audio-visual works and video on demand related features and functions
                                (including but not limited to clips, highlights, and other uploaded
                                content) may not be available.
                            </p>
                            <h2 className={styles.headerII}>a. License to THE IFC</h2>
                            <p className={styles.parag}>
                                Unless otherwise agreed to in a written agreement between you and THE
                                IFC that was signed by an authorized representative of THE IFC, if you
                                submit, transmit, display, perform, post, or store User Content using
                                the THE IFC Services, you grant THE IFC and its sub-licensees, to the
                                furthest extent and for the maximum duration permitted by applicable law
                                (including in perpetuity if permitted under applicable law), an
                                unrestricted, worldwide, irrevocable, fully sub-licenseable,
                                nonexclusive, and royalty-free right to: (a) use, reproduce, modify,
                                adapt, publish, translate, create derivative works from, distribute,
                                perform, and display such User Content (including without limitation for
                                promoting and redistributing part or all of the THE IFC Services (and
                                derivative works thereof) in any form, format, media, or media channels
                                now known or later developed or discovered; and (b) use the name,
                                identity, likeness, and voice (or other biographical information) that
                                you submit in connection with such User Content. Should such User
                                Content contain the name, identity, likeness, and voice (or other
                                biographical information) of third parties, you represent and warrant
                                that you have obtained the appropriate consents and/or licenses for your
                                use of such features and that THE IFC and its sub-licensees are allowed
                                to use them to the extent indicated in these Terms of Service.
                            </p>
                            <h2 className={styles.headerII}>
                                b. User Content Representations and Warranties
                            </h2>
                            <p className={styles.parag}>
                                You are solely responsible for your User Content and the consequences of
                                posting or publishing it. You represent and warrant that: (1) you are
                                the creator or own or control all right in and to the User Content or
                                otherwise have sufficient rights and authority to grant the rights
                                granted herein; (2) your User Content does not and will not: (a)
                                infringe, violate, or misappropriate any third-party right, including
                                any copyright, trademark, patent, trade secret, moral right, privacy
                                right, right of publicity, or any other intellectual property or
                                proprietary right, or (b) defame any other person; (3) your User Content
                                does not contain any viruses, adware, spyware, worms, or other harmful
                                or malicious code; and (4) unless you have received prior written
                                authorization, your User Content specifically does not contain any
                                pre-release or non-public beta software or game content or any
                                confidential information of THE IFC or third parties. THE IFC reserves
                                all rights and remedies against any users who breach these
                                representations and warranties.
                            </p>
                            <h2 className={styles.headerII}>
                                c. Content is Uploaded at Your Own Risk
                            </h2>
                            <p className={styles.parag}>
                                THE IFC uses reasonable security measures in order to attempt to protect
                                User Content against unauthorized copying and distribution. However, THE
                                IFC does not guarantee that any unauthorized copying, use, or
                                distribution of User Content by third parties will not take place. To
                                the furthest extent permitted by applicable law, you hereby agree that
                                THE IFC shall not be liable for any unauthorized copying, use, or
                                distribution of User Content by third parties and release and forever
                                waive any claims you may have against THE IFC for any such unauthorized
                                copying or usage of the User Content, under any theory. THE SECURITY
                                MEASURES TO PROTECT USER CONTENT USED BY THE IFC HEREIN ARE PROVIDED AND
                                USED “AS-IS” AND WITH NO WARRANTIES, GUARANTEES, CONDITIONS, ASSURANCES,
                                OR OTHER TERMS THAT SUCH SECURITY MEASURES WILL WITHSTAND ATTEMPTS TO
                                EVADE SECURITY MECHANISMS OR THAT THERE WILL BE NO CRACKS, DISABLEMENTS,
                                OR OTHER CIRCUMVENTION OF SUCH SECURITY MEASURES.
                            </p>
                            <h2 className={styles.headerII}>d. Political Activity</h2>
                            <p className={styles.parag}>
                                Subject to these Terms of Service, you may share political opinions;
                                participate in political activity; and provide links to a political
                                committee’s official website, including the contribution page of a
                                political committee. You agree, however, that these activities are
                                entirely your own. Moreover, by engaging in these activities, you
                                represent and warrant that you are eligible to engage in them under
                                applicable law, and that you will abide by all relevant laws and
                                regulations while doing so.
                            </p>
                            <p className={styles.parag}>
                                You agree not to solicit the use of or use any THE IFC monetization tool
                                for the purpose of making or delivering a contribution to a candidate,
                                candidate’s committee, political action committee, ballot committee, or
                                any other campaign committee, or otherwise for the purpose of
                                influencing any election. Candidates for political office are not
                                eligible to use any THE IFC monetization tool on their channels.
                            </p>

                            <h1 className={styles.headerI}>9. Prohibited Conduct</h1>
                            <p className={styles.parag}>
                                YOU AGREE NOT TO violate any law, contract, intellectual property, or
                                other third-party right; not to commit a tort, and that you are solely
                                responsible for your conduct while on the THE IFC Services.
                            </p>
                            <p className={styles.parag}>
                                You agree that you will comply with these Terms of Service and will not:
                                <br/><br/>
                                i. create, upload, transmit, distribute, or store any content that is
                                inaccurate, unlawful, infringing, defamatory, obscene, pornographic,
                                invasive of privacy or publicity rights, harassing, threatening,
                                abusive, inflammatory, or otherwise objectionable;<br/><br/>
                                ii. impersonate any person or entity; falsely claim an affiliation with
                                any person or entity; access the THE IFC Services accounts of others
                                without permission; forge another person’s digital signature;
                                misrepresent the source, identity, or content of information transmitted
                                via the THE IFC Services; or perform any other similar fraudulent
                                activity;<br/><br/>
                                iii. send junk mail or spam to users of the THE IFC Services, including
                                without limitation unsolicited advertising, promotional materials, or
                                other solicitation material; bulk mailing of commercial advertising,
                                chain mail, informational announcements, charity requests, petitions for
                                signatures, or any of the preceding things related to promotional
                                giveaways (such as raffles and contests); and other similar activities;
                                <br/><br/>
                                iv. harvest or collect email addresses or other contact information of
                                other users from the THE IFC Services;<br/><br/>
                                v. defame, harass, abuse, threaten, or defraud users of the THE IFC
                                Services, or collect or attempt to collect, personal information about
                                users or third parties without their consent;<br/><br/>
                                vi. delete, remove, circumvent, disable, damage, or otherwise interfere
                                with (a) security-related features of the THE IFC Services or User
                                Content, (b) features that prevent or restrict use or copying of any
                                content accessible through the THE IFC Services, (c) features that
                                enforce limitations on the use of the THE IFC Services or User Content,
                                or (d) the copyright or other proprietary rights notices on the THE IFC
                                Services or User Content;<br/><br/>
                                vii. reverse engineer, decompile, disassemble, or otherwise attempt to
                                discover the source code of the THE IFC Services or any part thereof,
                                except and only to the extent that this activity is expressly permitted
                                by the law of your jurisdiction of residence;<br/><br/>
                                viii. modify, adapt, translate, or create derivative works based upon
                                the THE IFC Services or any part thereof, except and only to the extent
                                that such activity is expressly permitted by applicable law
                                notwithstanding this limitation;<br/><br/>
                                ix. interfere with or damage the operation of the THE IFC Services or
                                any user’s enjoyment of them, by any means, including uploading or
                                otherwise disseminating viruses, adware, spyware, worms, or other
                                malicious code; x. access any website, server, software application, or
                                other computer resource owned, used, and/or licensed by THE IFC,
                                including but not limited to the THE IFC Services, by means of any
                                robot, spider, scraper, crawler, or other automated means for any
                                purpose, or bypass any measures THE IFC may use to prevent or restrict
                                access to any website, server, software application, or other computer
                                resource owned, used, and/or licensed by THE IFC, including but not
                                limited to the THE IFC Services;<br/><br/>
                                xi. manipulate identifiers in order to disguise the origin of any User
                                Content transmitted through the THE IFC Services;<br/><br/>
                                xii. interfere with or disrupt the THE IFC Services or servers or
                                networks connected to the THE IFC Services, or disobey any requirements,
                                procedures, policies, or regulations of networks connected to the THE
                                IFC Services; use the THE IFC Services in any manner that could
                                interfere with, disrupt, negatively affect, or inhibit other users from
                                fully enjoying the THE IFC Services, or that could damage, disable,
                                overburden, or impair the functioning of the THE IFC Services in any
                                manner;<br/><br/>
                                xiii. use or attempt to use another user’s account without authorization
                                from that user and THE IFC;<br/><br/>
                                xiv. attempt to circumvent any content filtering techniques we employ,
                                or attempt to access any service or area of the THE IFC Services that
                                you are not authorized to access;<br/><br/>
                                xv. attempt to indicate in any manner, without our prior written
                                permission, that you have a relationship with us or that we have
                                endorsed you or any products or services for any purpose; and<br/><br/>
                                xvi. use the THE IFC Services for any illegal purpose, or in violation
                                of any local, state, national, or international law or regulation,
                                including without limitation laws governing intellectual property and
                                other proprietary rights, data protection, and privacy.
                            </p>
                            <p className={styles.parag}>
                                To the extent permitted by applicable law, THE IFC takes no
                                responsibility and assumes no liability for any User Content or for any
                                loss or damage resulting therefrom, nor is THE IFC liable for any
                                mistakes, defamation, slander, libel, omissions, falsehoods, obscenity,
                                pornography, or profanity you may encounter when using the THE IFC
                                Services. Your use of the THE IFC Services is at your own risk. In
                                addition, these rules do not create any private right of action on the
                                part of any third party or any reasonable expectation that the THE IFC
                                Services will not contain any content that is prohibited by such rules.
                            </p>
                            <p className={styles.parag}>
                                THE IFC is not liable for any statements or representations included in
                                User Content. THE IFC does not endorse any User Content, opinion,
                                recommendation, or advice expressed therein, and THE IFC expressly
                                disclaims any and all liability in connection with User Content. To the
                                fullest extent permitted by applicable law, THE IFC reserves the right
                                to remove, screen, or edit any User Content posted or stored on the THE
                                IFC Services at any time and without notice, including where such User
                                Content violates these Terms of Service or applicable law, and you are
                                solely responsible for creating backup copies of and replacing any User
                                Content you post or store on the THE IFC Services at your sole cost and
                                expense. Any use of the THE IFC Services in violation of the foregoing
                                violates these Terms of Service and may result in, among other things,
                                termination or suspension of your rights to use the THE IFC Services.
                            </p>
                            <p className={styles.parag}>
                                For Residents of the Republic of Korea, except in the case where THE IFC
                                reasonably considers that giving notice is legally prohibited (for
                                instance, when providing notice would either (i) violate applicable
                                laws, regulations, or orders from regulatory authorities or (ii)
                                compromise an ongoing investigation conducted by a regulatory authority)
                                or that any notice may cause harm to you, third parties, THE IFC, and/or
                                its affiliates (for instance, when providing notice harms the security
                                of the THE IFC Services), THE IFC will without delay notify you of the
                                reason for taking the relevant step.
                            </p>

                            <h1 className={styles.headerI}>10. Trademarks</h1>
                            <p className={styles.parag}>
                                THE IFC, the THE IFC logos, and any other product or service name, logo,
                                or slogan used by THE IFC, and the look and feel of the THE IFC
                                Services, including all page headers, custom graphics, button icons, and
                                scripts, are trademarks or trade dress of THE IFC, and may not be used
                                in whole or in part in connection with any product or service that is
                                not THE IFC’s, in any manner that is likely to cause confusion among
                                customers, or in any manner that disparages or discredits THE IFC,
                                without our prior written permission. Any use of these trademarks must
                                be in accordance with the THE IFC{" "}
                                <Link to={'/trademarks-guidelines'} className={styles.a}>
                                    Trademarks Guidelines
                                </Link>
                                .
                            </p>
                            <p className={styles.parag}>
                                All other trademarks referenced in the THE IFC Services are the property
                                of their respective owners. Reference on the THE IFC Services to any
                                products, services, processes, or other information by trade name,
                                trademark, manufacturer, supplier, or otherwise does not constitute or
                                imply endorsement, sponsorship, or recommendation thereof by us or any
                                other affiliation.
                            </p>

                            <h1 className={styles.headerI}>11. Idea Submission</h1>
                            <p className={styles.parag}>
                                By submitting ideas, suggestions, documents, and/or proposals (the
                                “Submissions”) to THE IFC or its employees, you acknowledge and agree
                                that THE IFC shall be entitled to use or disclose such Submissions for
                                any purpose in any way without providing compensation or credit to you.
                            </p>

                            <h1 className={styles.headerI}>12. Termination</h1>
                            <p className={styles.parag}>
                                To the fullest extent permitted by applicable law, THE IFC reserves the
                                right, without notice and in our sole discretion, to terminate your
                                license to use the THE IFC Services (including to post User Content) and
                                to block or prevent your future access to and use of the THE IFC
                                Services, including where we reasonably consider that: (a) your use of
                                the THE IFC Services violates these Terms of Service or applicable law;
                                (b) you fraudulently use or misuse the THE IFC Services; or (c) we are
                                unable to continue providing the THE IFC Services to you due to
                                technical or legitimate business reasons. Our right to terminate your
                                license includes the ability to terminate or to suspend your access to
                                any purchased products or services, including any subscriptions. To the
                                fullest extent permitted by applicable law, your only remedy with
                                respect to any dissatisfaction with: (i) the THE IFC Services, (ii) any
                                term of these Terms of Service, (iii) any policy or practice of THE IFC
                                in operating the THE IFC Services, or (iv) any content or information
                                transmitted through the THE IFC Services, is to terminate your account
                                and to discontinue use of any and all parts of the THE IFC Services.
                            </p>
                            <p className={styles.parag}>
                                For residents of the Republic of Korea, except in the case where THE IFC
                                reasonably considers that giving notice is legally prohibited (for
                                instance, when providing notice would either (i) violate applicable
                                laws, regulations, or orders from regulatory authorities or (ii)
                                compromise an ongoing investigation conducted by a regulatory authority)
                                or that any notice may cause harm to you, third parties, THE IFC, and/or
                                its affiliates (for instance, when providing notice harms the security
                                of the THE IFC Services), THE IFC will without delay notify you of the
                                reason for taking the relevant step.
                            </p>

                            <h1 className={styles.headerI}>13. Disputes</h1>
                            <h2 className={styles.headerII}>a. Indemnification</h2>
                            <p className={styles.parag}>
                                To the fullest extent permitted by applicable law, you agree to
                                indemnify, defend, and hold harmless THE IFC, its affiliated companies,
                                and each of our respective contractors, employees, officers, directors,
                                agents, third-party suppliers, licensors, and partners (individually and
                                collectively, the “THE IFC Parties”) from any claims, losses, damages,
                                demands, expenses, costs, and liabilities, including legal fees and
                                expenses, arising out of or related to your access, use, or misuse of
                                the THE IFC Services, any User Content you post, store, or otherwise
                                transmit in or through the THE IFC Services, your violation of the
                                rights of any third party, any violation by you of these Terms of
                                Service, or any breach of the representations, warranties, and covenants
                                made by you herein. You agree to promptly notify the THE IFC Parties of
                                any third-party claim, and THE IFC reserves the right, at your expense,
                                to assume the exclusive defense and control of any matter for which you
                                are required to indemnify THE IFC, and you agree to cooperate with THE
                                IFC’s defense of these claims. THE IFC will use reasonable efforts to
                                notify you of any such claim, action, or proceeding upon becoming aware
                                of it.
                            </p>
                            <h2 className={styles.headerII}>
                                b. Disclaimers; No Warranties
                            </h2>
                            <p className={styles.parag}>
                                TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW: (A) THE THE IFC
                                SERVICES AND THE CONTENT AND MATERIALS CONTAINED THEREIN ARE PROVIDED ON
                                AN “AS IS” BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR
                                IMPLIED, EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY IN WRITING BY THE
                                IFC; (B) THE THE IFC PARTIES DISCLAIM ALL OTHER WARRANTIES, STATUTORY,
                                EXPRESS, OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES
                                OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
                                NON-INFRINGEMENT AS TO THE THE IFC SERVICES, INCLUDING ANY INFORMATION,
                                CONTENT, OR MATERIALS CONTAINED THEREIN; (C) THE IFC DOES NOT REPRESENT
                                OR WARRANT THAT THE CONTENT OR MATERIALS ON THE THE IFC SERVICES ARE
                                ACCURATE, COMPLETE, RELIABLE, CURRENT, OR ERROR-FREE; (D) THE IFC IS NOT
                                RESPONSIBLE FOR TYPOGRAPHICAL ERRORS OR OMISSIONS RELATING TO TEXT OR
                                PHOTOGRAPHY; AND (E) WHILE THE IFC ATTEMPTS TO MAKE YOUR ACCESS AND USE
                                OF THE THE IFC SERVICES SAFE, THE IFC CANNOT AND DOES NOT REPRESENT OR
                                WARRANT THAT THE THE IFC SERVICES OR OUR SERVER(S) ARE FREE OF VIRUSES
                                OR OTHER HARMFUL COMPONENTS, AND THEREFORE, YOU SHOULD USE
                                INDUSTRY-RECOGNIZED SOFTWARE TO DETECT AND DISINFECT VIRUSES FROM ANY
                                DOWNLOAD. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY
                                YOU FROM THE IFC OR THROUGH THE THE IFC SERVICES WILL CREATE ANY
                                WARRANTY NOT EXPRESSLY STATED HEREIN.
                            </p>
                            <h2 className={styles.headerII}>
                                c. Limitation of Liability and Damages
                            </h2>
                            <p className={styles.parag}>
                                i. Limitation of Liability{"\n"}
                                TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW: (A) IN NO EVENT SHALL
                                THE IFC OR THE THE IFC PARTIES BE LIABLE FOR ANY DIRECT, SPECIAL,
                                INDIRECT, OR CONSEQUENTIAL DAMAGES, OR ANY OTHER DAMAGES OF ANY KIND,
                                INCLUDING BUT NOT LIMITED TO LOSS OF USE, LOSS OF PROFITS, OR LOSS OF
                                DATA, WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED
                                TO NEGLIGENCE), OR OTHERWISE, ARISING OUT OF OR IN ANY WAY CONNECTED
                                WITH THE USE OF OR INABILITY TO USE THE THE IFC SERVICES, THE CONTENT OR
                                THE MATERIALS, INCLUDING WITHOUT LIMITATION ANY DAMAGES CAUSED BY OR
                                RESULTING FROM RELIANCE ON ANY INFORMATION OBTAINED FROM THE IFC, OR
                                THAT RESULT FROM MISTAKES, OMISSIONS, INTERRUPTIONS, DELETION OF FILES
                                OR EMAIL, ERRORS, DEFECTS, VIRUSES, DELAYS IN OPERATION OR TRANSMISSION,
                                OR ANY FAILURE OF PERFORMANCE, WHETHER OR NOT RESULTING FROM ACTS OF
                                GOD, COMMUNICATIONS FAILURE, THEFT, DESTRUCTION, OR UNAUTHORIZED ACCESS
                                TO THE IFC’S RECORDS, PROGRAMS, OR SERVICES; AND (B) IN NO EVENT SHALL
                                THE AGGREGATE LIABILITY OF THE IFC, WHETHER IN CONTRACT, WARRANTY, TORT
                                (INCLUDING NEGLIGENCE, WHETHER ACTIVE, PASSIVE, OR IMPUTED), PRODUCT
                                LIABILITY, STRICT LIABILITY, OR OTHER THEORY, ARISING OUT OF OR RELATING
                                TO THE USE OF OR INABILITY TO USE THE THE IFC SERVICES EXCEED THE AMOUNT
                                PAID BY YOU, IF ANY, FOR ACCESSING THE THE IFC SERVICES DURING THE
                                TWELVE (12) MONTHS IMMEDIATELY PRECEDING THE DATE OF THE CLAIM OR ONE
                                HUNDRED DOLLARS, WHICHEVER IS GREATER. TO THE EXTENT THAT APPLICABLE LAW
                                PROHIBITS LIMITATION OF SUCH LIABILITY, THE IFC SHALL LIMIT ITS
                                LIABILITY TO THE FULL EXTENT ALLOWED BY APPLICABLE LAW.<br/><br/>
                                ii. Reference Sites{"\n"}
                                THESE LIMITATIONS OF LIABILITY ALSO APPLY WITH RESPECT TO DAMAGES
                                INCURRED BY YOU BY REASON OF ANY PRODUCTS OR SERVICES SOLD OR PROVIDED
                                ON ANY REFERENCE SITES OR OTHERWISE BY THIRD PARTIES OTHER THAN THE IFC
                                AND RECEIVED THROUGH OR ADVERTISED ON THE THE IFC SERVICES OR RECEIVED
                                THROUGH ANY REFERENCE SITES.<br/><br/>
                                iii. Basis of the Bargain{"\n"}
                                YOU ACKNOWLEDGE AND AGREE THAT THE IFC HAS OFFERED THE THE IFC SERVICES,
                                USER CONTENT, MATERIALS, AND OTHER CONTENT AND INFORMATION, SET ITS
                                PRICES, AND ENTERED INTO THESE TERMS OF SERVICE IN RELIANCE UPON THE
                                WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN, THAT
                                THE WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN
                                REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN YOU AND THE
                                IFC, AND THAT THE WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY SET
                                FORTH HEREIN FORM AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN YOU AND THE
                                IFC. THE IFC WOULD NOT BE ABLE TO PROVIDE THE THE IFC SERVICES TO YOU ON
                                AN ECONOMICALLY REASONABLE BASIS WITHOUT THESE LIMITATIONS.
                            </p>
                            <h2 className={styles.headerII}>
                                d. Applicable Law and Venue
                            </h2>
                            <p className={styles.parag}>
                                (i) To the fullest extent permitted by applicable law, you and THE IFC
                                agree that if you are a Subscribing Organization or a consumer resident
                                of a jurisdiction other than those in (ii) below, the following
                                governing law and arbitration provision applies:<br/><br/>
                                PLEASE READ THE FOLLOWING CAREFULLY BECAUSE IT REQUIRES YOU TO ARBITRATE
                                DISPUTES WITH THE IFC AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF
                                FROM THE IFC.<br/><br/>
                                You and THE IFC agree to arbitrate any dispute arising from these Terms
                                of Service or your use of the THE IFC Services, except that you and THE
                                IFC are not required to arbitrate any dispute in which either party
                                seeks equitable and other relief for the alleged unlawful use of
                                copyrights, trademarks, trade names, logos, trade secrets, or patents.
                                ARBITRATION PREVENTS YOU FROM SUING IN COURT OR FROM HAVING A JURY
                                TRIAL. You and THE IFC agree that you will notify each other in writing
                                of any dispute within thirty (30) days of when it arises. Notice to THE
                                IFC shall be sent to: THE IFC, 14-30 Rue de Mantes, 92700 Colombes. You
                                and THE IFC further agree: to attempt informal resolution prior to any
                                demand for arbitration. Whether the dispute is heard in arbitration or
                                in court, you and THE IFC will not commence against the other a class
                                action, class arbitration, or other representative action or proceeding.
                                <br/><br/>
                                (ii) If you are a resident in any jurisdiction in which the provision in
                                the section above is found to be unenforceable, then any disputes,
                                claims, or causes of action arising out of or in connection with these
                                Terms of Service will be governed by and construed under the laws of
                                your jurisdiction of residence, and shall be resolved by competent civil
                                courts within your jurisdiction of residence.
                            </p>
                            <h2 className={styles.headerII}>e. Claims</h2>
                            <p className={styles.parag}>
                                YOU AND THE IFC AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED
                                TO THE THE IFC SERVICES MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE
                                CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY
                                BARRED.
                            </p>

                            <h1 className={styles.headerI}>14. Miscellaneous</h1>
                            <h2 className={styles.headerII}>a. Waiver</h2>
                            <p className={styles.parag}>
                                If we fail to exercise or enforce any right or provision of these Terms
                                of Service, it will not constitute a waiver of such right or provision.
                                Any waiver of any provision of these Terms of Service will be effective
                                only if in writing and signed by the relevant party.
                            </p>
                            <h2 className={styles.headerII}>b. Severability</h2>
                            <p className={styles.parag}>
                                If any provision of these Terms of Service is held to be unlawful, void,
                                or for any reason unenforceable, then that provision will be limited or
                                eliminated from these Terms of Service to the minimum extent necessary
                                and will not affect the validity and enforceability of any remaining
                                provisions.
                            </p>
                            <h2 className={styles.headerII}>c. Assignment</h2>
                            <p className={styles.parag}>
                                These Terms of Service, and any rights and licenses granted hereunder,
                                may not be transferred or assigned by you, but may be assigned by THE
                                IFC without restriction. Any assignment attempted to be made in
                                violation of this Terms of Service shall be void.
                            </p>
                            <h2 className={styles.headerII}>d. Survival</h2>
                            <p className={styles.parag}>
                                Upon termination of these Terms of Service, any provision which, by its
                                nature or express terms should survive, will survive such termination or
                                expiration, including, but not limited to, Sections 7, 8, 10, 13, and
                                14.
                            </p>
                            <h2 className={styles.headerII}>e. Entire Agreement</h2>
                            <p className={styles.parag}>
                                The Terms of Service, which incorporate the Terms of Sale, is the entire
                                agreement between you and THE IFC relating to the subject matter herein
                                and will not be modified except by a writing signed by authorized
                                representatives of both parties, or by a change to these Terms of
                                Service made by THE IFC as set forth in Section 6 above.
                            </p>
                            <p className={styles.parag}>
                                The THE IFC Services are offered by THE IFC, located at 14-30 rue de
                                Mantes, email:{" "}
                                <a
                                    className={styles.a}
                                    href="mailto:contact@the-ifc.com"
                                >
                                    contact@the-ifc.com
                                </a>
                                .
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
