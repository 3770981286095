import cn from 'classnames'
import React from 'react'
import {Link} from 'react-router-dom'
import {useMobile} from '../../hooks/useMobile'
import {SvgSprite} from '../../ui/SvgSprite/SvgSprite'
// Styles
import styles from './index.module.scss'
import logo from '../../../assets/images/logo.png'

export const Cgv = () => {
    const isTablet = useMobile(1300)
    return (
        <div className={styles.pageWrapper}>
            <div className={cn({
                [styles.pageWrapperContent]: !isTablet,
                [styles.pageWrapperMobile]: isTablet
            })}>
                {!isTablet && (
                    <div className={styles.img}>
                        <img
                            className={styles.banner}
                            src={logo}
                            alt="Ifc Banner"
                        />
                    </div>
                )}
                <div className={cn(styles.text, {
                    [styles.textMobile]: isTablet,
                })}>
                    <div className={styles.header}>
                        <Link to={'/auth/login'} className={styles.matchArrowLink}>
                            <SvgSprite spriteID={'leftArrow'}/>
                        </Link>
                        Terms of Sale
                    </div>
                    <div className={styles.body}>
                        <div className={styles.containerContent}>
                            <p className={styles.parag}>Last udpated: April 10, 2023</p>
                            <h1 className={styles.headerI}>1. Introduction</h1>
                            <p className={styles.parag}>
                                Certain products, services, and content may from time to time be made
                                available to you (&quot;you&quot; or &quot;User&quot;) by THE IFC or their affiliates
                                (referred to herein as &quot;us&quot;, &quot;we&quot;, or &quot;THE IFC&quot;) in connection with the
                                website available at{" "}
                                <a
                                    className={styles.a}
                                    href={"https://www.the-ifc.fr"}
                                >
                                    https://www.the-ifc.fr
                                </a>
                                , THE IFC’s network of websites and software applications, or other
                                products or services offered by THE IFC (the &quot;THE IFC Services&quot;). By
                                purchasing products, services, or content, including any purchases made
                                as a gift to another user, through the THE IFC Services that link to
                                these Terms of Sale (&quot;Ancillary Products and Services&quot;), you signify
                                that you have read, understand, and agree to be bound by the Terms of
                                Sale in effect at the time of purchase (“Terms of Sale&quot;). Your purchases
                                of Ancillary Products and Services and your use of the THE IFC Services
                                are also governed by THE IFC’s{" "}
                                <Link
                                    className={styles.a}
                                    to="/terms-of-service"
                                >
                                    Terms of Service
                                </Link>
                                , into which these Terms of Sale are incorporated by reference.
                            </p>
                            <p className={styles.parag}>
                                PLEASE READ THESE TERMS OF SALE AND THE TERMS OF SERVICE CAREFULLY AS
                                THEY CONTAIN IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS,
                                REMEDIES, AND OBLIGATIONS. THESE INCLUDE VARIOUS LIMITATIONS AND
                                EXCLUSIONS, AND A DISPUTE RESOLUTION CLAUSE THAT GOVERNS HOW DISPUTES
                                WILL BE RESOLVED.
                            </p>

                            <h1 className={styles.headerI}>
                                2. Modifications of these Terms of Sale
                            </h1>
                            <p className={styles.parag}>
                                THE IFC may amend any of the terms of these Terms of Sale by posting the
                                amended terms and updating the “Last Updated&quot; date above. Please check
                                these Terms of Sale periodically for those changes. Any modified terms
                                will apply to any purchase or renewal of Subscription Services (as
                                defined below) made after such changes are posted on the THE IFC
                                Services. Therefore, you should review these Terms of Sale prior to each
                                purchase so you will understand the terms applicable to such
                                transaction. If you do not agree to these Terms of Sale, do not make any
                                purchases on the THE IFC Services.
                            </p>
                            <p className={styles.parag}>
                                For residents of the European Union, the European Economic Area, the
                                United Kingdom, or Switzerland who are acting as a consumer (i.e., for a
                                purpose which can be regarded as being outside your trade, craft, or
                                profession) (&quot;Consumer&quot;), with regard to active Subscription Services,
                                THE IFC will provide reasonable prior notice of changes to these Terms
                                of Sale. To the extent permitted by applicable law, not canceling your
                                Subscription Services before the next renewal of your Subscription
                                Services will be deemed your consent to the modified terms that will
                                take effect at the time of your next renewal. Where required by
                                applicable law, THE IFC will ask for your consent to the modified Terms.
                            </p>

                            <h1 className={styles.headerI}>
                                3. Purchase Qualifications; Account Security
                            </h1>
                            <p className={styles.parag}>
                                To make a purchase on the THE IFC Services, you must be a registered THE
                                IFC Services user and comply with these Terms of Sale and the{" "}
                                <Link
                                    className={styles.a}
                                    to="/terms-of-service"
                                >
                                    Terms of Service
                                </Link>
                                . You acknowledge that you are responsible for maintaining the security
                                of, and restricting access to your account and password, and you agree
                                to accept responsibility for all purchases and other activities that
                                occur under your account. THE IFC sells its Ancillary Products and
                                Services only to those users who can legally make purchases with a
                                credit card. If you are between the ages of 13 and 18 (or the legal age
                                of majority in your state of residence), you may make purchases on the
                                THE IFC Services only with the permission of a parent or guardian. THE
                                IFC reserves the right to refuse or cancel orders or terminate accounts
                                at any time in its sole discretion.
                            </p>

                            <h1 className={styles.headerI}>
                                4. Subscription Services Terms
                            </h1>
                            <p className={styles.parag}>
                                THE IFC may offer certain Ancillary Products and Services in connection
                                with the THE IFC Services on a subscription basis with recurring
                                payments (&quot;Subscription Services&quot;) as disclosed to you when you
                                subscribe to any Subscription Services. Subscription Services may renew
                                automatically, and you agree that we are authorized to charge you for
                                payment on a recurring basis prior to each renewal. You agree that your
                                Subscription Service will renew continuously for the agreed subscription
                                time period until you cancel it.
                            </p>
                            <p className={styles.parag}>
                                THE IFC reserves the right to discontinue or modify any subscription fee
                                payment option. If we discontinue or modify a subscription payment
                                option, we will provide notice of such discontinuance or modification by
                                email or through the THE IFC Services in advance of the next billing
                                date. If you are signing up under any promotional subscription fee, some
                                additional restrictions may apply. These restrictions, if any, will be
                                provided to you before you sign up for the applicable Subscription
                                Service that is subject to the promotion.
                            </p>
                            <h2 className={styles.headerII}>
                                a. Subscription Services - Automatic Payments
                            </h2>
                            <p className={styles.parag}>
                                If you choose a Subscription Service (including any pre-paid
                                subscription option, gift subscription, or channel subscription that
                                reverts to a monthly payment plan at the expiration of the pre-paid
                                period), you hereby grant THE IFC permission to automatically charge the
                                subscription fee to your chosen payment method at the beginning of each
                                applicable payment period until you cancel the Subscription Service.
                                Your access, or the gift recipient’s access, to the Subscription
                                Services will not be established until THE IFC has verified that the
                                credit card or other payment information you provide THE IFC for payment
                                is accurate and that your payment method account is in good standing.
                            </p>
                            <p className={styles.parag}>
                                You further agree that THE IFC may charge any other applicable fees for
                                the THE IFC Services or Ancillary Products and Services, if disclosed to
                                you in connection with your purchase, to your payment method, including
                                any early termination fee.
                            </p>
                            <p className={styles.parag}>
                                You are required to keep your billing information current, complete, and
                                accurate (for example, if you move, be sure to update your billing
                                address, and if you get a new credit/debit card, make sure you update
                                the card number and/or expiration date) and notify THE IFC if your
                                selected payment method is canceled (e.g., for loss or theft).
                            </p>
                            <p className={styles.parag}>
                                To the extent permitted by applicable law, THE IFC reserves the right to
                                suspend or cancel your access to any Subscription Service that is a part
                                of the THE IFC Services without notice (or after providing reasonable
                                notice where required by applicable law), upon rejection of any due
                                charges or if your payment method (or its agent or affiliate) causes the
                                return of payments previously made to THE IFC when you are liable for
                                the charges. Any other requirements that may apply to our right to
                                cancel your Subscription Service for your default in payment under
                                applicable law shall remain unaffected by the foregoing sentences.
                            </p>
                            <h2 className={styles.headerII}>
                                b. Cancellation of Subscription Services
                            </h2>
                            <p className={styles.parag}>
                                You may cancel your Subscription Service at any time by selecting the
                                &quot;Do Not Renew&quot; option under your subscription settings in the relevant
                                THE IFC Services, or by utilizing any other cancellation method that may
                                be available to you under applicable law. THE IFC may cancel your
                                Subscription Service by giving you notice by email or through the THE
                                IFC Services.
                            </p>
                            <p className={styles.parag}>
                                Either party’s cancellation under the prior paragraph is effective at
                                the end of the current payment period and your Subscription Services
                                will continue until the end of the current payment period.
                            </p>

                            <h1 className={styles.headerI}>
                                5 Virtual Goods and Virtual Money
                            </h1>
                            <p className={styles.parag}>
                                i. Our applications may include virtual currencies such as coins
                                (&quot;Virtual Money&quot;), items or services for use with our applications
                                (&quot;Virtual Goods&quot;). Virtual Money has no real monetary value and cannot
                                be exchanged for real money, goods, or services outside of the platform.
                            </p>
                            <p className={styles.parag}>
                                ii. You do not own Virtual Goods, Virtual Money and/or Subscriptions but
                                instead you purchase a limited personal revocable licence to use them -
                                any balance of Virtual Goods and/or Virtual Money does not reflect any
                                stored value.
                            </p>
                            <p className={styles.parag}>
                                iii. You agree that all sales by us to you of Virtual Money and/or
                                Virtual Goods are final, that we will not refund any transaction once it
                                has been made. Prices for all Virtual Money and Virtual Goods exclude
                                all applicable taxes and telecommunication charges, unless otherwise
                                indicated. To the extent required by law, you agree to pay any such
                                applicable taxes and telecommunication charges in line with applicable
                                law. If you live in the European Union you have certain rights to
                                withdraw from distance purchases; however, please note that when you
                                purchase a license to use Virtual Goods or Virtual Money from us, you
                                acknowledge and agree that we will begin the provision of the Virtual
                                Goods or Virtual Money to you promptly once your purchase is complete
                                and therefore your right of withdrawal is lost at this point. For the
                                purposes of this paragraph 4.3, a &quot;purchase&quot; is complete at the time our
                                servers validate your purchase and the applicable Virtual Goods and/or
                                Virtual Money are successfully credited to your account on our servers.
                            </p>
                            <p className={styles.parag}>
                                iv. Any risk of loss of Virtual Money which you purchase from us is
                                transferred to you upon completion of the purchase as described in
                                paragraph 5.3 above. Any risk of loss of Virtual Money that you receive
                                from us without making a purchase is transferred to you at the time the
                                Virtual Money is successfully credited to your account on our servers.
                            </p>
                            <p className={styles.parag}>
                                v. If you live in the European Union, we will provide you with a VAT
                                invoice where we are required to do so by law or where requested by you.
                                You agree that these invoices may be electronic in format.
                            </p>
                            <p className={styles.parag}>
                                vi. We reserve the right to control, regulate, change or remove any
                                Virtual Money, Virtual Goods without any liability to you at any time.
                            </p>
                            <p className={styles.parag}>
                                We may revise the pricing for Virtual Goods, Virtual Money offered
                                through the Services at any time. We may limit the total amount of
                                Virtual Goods or Virtual Money that may be purchased at any one time,
                                and/or limit the total amount of Virtual Money or Virtual Goods that may
                                be held in your account in the aggregate. You are only allowed to obtain
                                Virtual Money and Virtual Goods from us or our authorized partners
                                through the Services, and not in any other way.
                            </p>
                            <p className={styles.parag}>
                                vii. If we suspend or terminate your account in accordance with these
                                terms you may lose any Virtual Money, and Virtual Goods that you may
                                have and we will not compensate you for this loss or make any refund to
                                you.
                            </p>
                            <p className={styles.parag}>
                                viii. The charge for any individual item you can purchase via our
                                Website shall be as stated on our Website at the time you place the
                                order, except in the case of obvious error. The charge is inclusive of
                                all sales taxes and other charges. Depending on which bank you use,
                                additional charges may be issued by your bank; we have no control over
                                this and accept no liability in relation to the same. If you are unsure
                                whether you will be subject to such additional charges then you should
                                check with your bank before making a purchase via our Website. We accept
                                payment via our payment processing partners by credit card, debit card,
                                carrier billing and Paypal only. Our payment processing partners may
                                have their own terms and conditions and you should ensure you are in
                                agreement with these prior to making any payment. If your transaction
                                with our payment processing partners is not successful then your
                                purchase will not be fulfilled. Upon the completion of a successful
                                payment transaction then your purchase will be fulfilled to you as soon
                                as possible - we will endeavor to fulfill your order immediately at the
                                point of purchase.
                            </p>
                            <p className={styles.parag}>
                                ix. Users can earn Virtual Money by participating in activities on our
                                platform, such as taking part in competitions, joining clubs, or
                                achieving outstanding performances. Virtual Money can be used to access
                                premium benefits or services within the platform.
                            </p>
                            <p className={styles.parag}>
                                x. Users must not use Virtual Money to conduct illegal, deceptive, or
                                unethical activities. The platform reserves the right to suspend or
                                terminate access to Virtual Money for any user who breaches these
                                restrictions and limitations.
                            </p>
                            <p className={styles.parag}>
                                xi. Virtual Money is non-transferable outside the platform and may not
                                be sold, exchanged, or given to third parties without the platform&apos;s
                                prior written permission. Unauthorized attempts to transfer Virtual
                                Money may result in the suspension or termination of the user&apos;s account,
                                as well as the confiscation of any Virtual Money held by the user.
                            </p>
                            <p className={styles.parag}>
                                xii. Any attempt at fraud, abuse, or unauthorized use of Virtual Money,
                                including but not limited to exploiting technical vulnerabilities,
                                manipulating outcomes, or using multiple accounts to bypass limits, will
                                be considered a violation of these Terms and Conditions of Use. The
                                platform reserves the right to take appropriate action against users
                                involved in such behaviors, including but not limited to the suspension
                                or termination of the user&apos;s account, as well as the confiscation of any
                                Virtual Money held by the user.
                            </p>

                            <h1 className={styles.headerI}>6. Refund Policy</h1>
                            <p className={styles.parag}>
                                When you make a purchase on or receive a gift through the THE IFC
                                Services, you have immediate access to, and use of, the Ancillary
                                Products and Services you have purchased. Accordingly, except as
                                detailed in these Terms of Sale, to the extent permitted by applicable
                                law, cancellations are not permitted after a purchase, and we do not
                                offer any refunds or credits, including, without limitation, fees for
                                Subscription Services. THE IFC reserves the right to modify this refund
                                policy as set forth below at any time.
                            </p>
                            <p className={styles.parag}>
                                Unless otherwise specified in these Terms of Sale or required under the
                                applicable law in your jurisdiction, Virtual Goods and Virtual Money are
                                not returnable after purchase.
                            </p>

                            <h1 className={styles.headerI}>7. Payment Methods and Terms</h1>
                            <p className={styles.parag}>
                                We accept payment through major credit cards, certain debit cards,
                                PayPal, and/or such other payment methods we may make available to you
                                from time-to-time through our site (&quot;Payment Methods&quot;). You are subject
                                to all terms and conditions of the Payment Method you choose. By
                                submitting an order through the THE IFC Services, you represent and
                                warrant that you are authorized to use the designated Payment Method and
                                authorize THE IFC, or its designated payment processor, to charge the
                                Payment Method you specify for the purchase amount, including all taxes
                                and fees. All payments are to be made in Euros, except where other
                                currencies are offered via the Payment Methods made available by THE
                                IFC.
                            </p>
                            <p className={styles.parag}>
                                PLEASE NOTE: Our payment processing partners may request that you
                                provide certain personal data (e.g., a valid government-issued ID, your
                                legal name, address, and date of birth) for the purpose of making
                                payment through its financial institutions and complying with applicable
                                international, national, federal, state, and local laws and regulations.
                                They may also communicate directly with you regarding any issues with a
                                payment.
                            </p>
                            <p className={styles.parag}>
                                If a purchase has been declined online due to issues with your Payment
                                Method, we may suspend or cancel your order automatically. You are
                                responsible for resolving any problem we encounter in order to proceed
                                with your purchase. If the transaction is not accepted online, please
                                contact customer support at{" "}
                                <a
                                    className={styles.a}
                                    href="mailto:contact@the-ifc.com"
                                >
                                    contact@the-ifc.com
                                </a>
                                .
                            </p>
                            <p className={styles.parag}>
                                THE IFC may impose an additional transaction fee based on transactions
                                associated with the THE IFC Services, including a transaction fee
                                applied to purchases from third parties. Such transaction fee will be
                                disclosed to you prior to your consummation of the relevant transaction.
                            </p>

                            <h1 className={styles.headerI}>8. Regional Pricing</h1>
                            <p className={styles.parag}>
                                THE IFC may implement regional pricing programs in order to help all
                                viewers support their favorite creators. THE IFC may determine your
                                eligibility for regional pricing using information such as your IP
                                address, payment method location, self-reported residence, and
                                indicators of fraudulent activity (such as unusual transaction volume).
                                You agree that you will not misrepresent your place of residence (for
                                example, by using a VPN) to fraudulently qualify for these pricing
                                programs.
                            </p>

                            <h1 className={styles.headerI}>9. Third-Party Terms</h1>
                            <p className={styles.parag}>
                                Your purchase and enjoyment of Ancillary Products and Services may be
                                subject to additional third-party legal terms. You are bound by such
                                third-party legal terms, and we encourage you to read them.
                            </p>

                            <h1 className={styles.headerI}>10. Delivery</h1>
                            <p className={styles.parag}>
                                With respect to Ancillary Products and Services you purchase in
                                connection with the THE IFC Services, to the extent permitted by
                                applicable law, you acknowledge and agree that upon making such
                                Ancillary Products and Services available to you (or to their intended
                                authorized recipients), THE IFC will have fully satisfied its obligation
                                to deliver or otherwise provide such products, services, or content,
                                regardless of any failure or inability to use such products, services,
                                or content.
                            </p>

                            <h1 className={styles.headerI}>
                                11. Service Descriptions, Availability, and Errors
                            </h1>
                            <p className={styles.parag}>
                                THE IFC and its suppliers and licensors continually upgrade and revise
                                the THE IFC Services to provide you with new Ancillary Products and
                                Services. To the extent permitted by applicable law, THE IFC may revise,
                                discontinue, or modify Ancillary Products and Services at any time
                                without prior notice to you. THE IFC shall have no liability of any kind
                                if an Ancillary Product or Service that has been ordered is unavailable.
                                If necessary, THE IFC reserves the right to cancel an order or
                                substitute an Ancillary Product or Service of equal or greater value
                                when a product, service, or content is unavailable.
                            </p>
                            <p className={styles.parag}>
                                We attempt to be as accurate as possible and eliminate errors on the THE
                                IFC Services; however, we do not warrant that any Ancillary Products and
                                Services or description, photograph, pricing, or other information of a
                                product, service, or content is accurate, complete, reliable, current,
                                or error-free. In the event of an error, whether on the THE IFC
                                Services, in an order confirmation, in processing an order, delivering
                                an Ancillary Product or Service, or otherwise, we reserve the right to
                                correct such error and revise your order accordingly if necessary
                                (including charging the correct price) or to cancel the order and refund
                                any amount charged. In addition, we may, in lieu of a refund as provided
                                in this paragraph, opt to provide you with a service credit, with a
                                value equal to the amount charged to your payment method. THE IFC
                                reserves the right to determine and modify from time to time the exact
                                nature of any such service credit, including conversion into one or more
                                different types of Ancillary Products and Services credits. Your sole
                                remedy in the event of an error is to cancel your order and obtain a
                                refund or credit as set forth above.
                            </p>

                            <h1 className={styles.headerI}>
                                12. EU/EEA/UK/CH Warranty Rights
                            </h1>
                            <p className={styles.parag}>
                                For residents of the European Union or the European Economic Area, the
                                United Kingdom, or Switzerland who are acting as a Consumer, there are
                                warranty rights under applicable law in case of any non-conformity of
                                digital content and services. Such rights shall remain unaffected by
                                these Terms of Sale. In such case, THE IFC offers no further implied or
                                statutory warranties or conditions with regard to digital content and
                                services, and explicitly disclaims all implied warranties and conditions
                                to the maximum extent permitted by applicable law.
                            </p>
                            <p className={styles.parag}>
                                You acknowledge that we may change the content and character of active
                                Subscription Services from time to time without causing its
                                non-conformity, including when needed to comply with applicable law or
                                regulation, or for justified commercial, security, or operational
                                reasons, and including to make any improvements to your access to the
                                THE IFC Services.
                            </p>

                            <h1 className={styles.headerI}>13. Taxes</h1>
                            <p className={styles.parag}>
                                You are responsible for any applicable national, state, or local sales
                                or use taxes, value added taxes (&quot;VAT&quot;) or similar taxes or fees payable
                                in connection with your purchase of any products, services, or content
                                for yourself or as a gift. As the purchaser of a subscription or digital
                                good for another party as a gift, taxes, or other fees on that
                                transaction will be calculated based on your country of residence. If
                                you do not pay such sales or other tax or fee on a transaction, you will
                                be responsible for such taxes or fees in the event that they are later
                                determined to be payable on such sale, and THE IFC reserves the right to
                                collect such taxes or other fees from you at any time.
                            </p>

                            <h1 className={styles.headerI}>14. Miscellaneous</h1>
                            <p className={styles.parag}>
                                THE IFC is the seller of record of Ancillary Products and Services
                                unless otherwise disclosed in connection with a particular Ancillary
                                Product or Service.
                            </p>

                            <h1 className={styles.headerI}>15. Questions</h1>
                            <p className={styles.parag}>
                                For more information, please write to:<br/>
                                THE IFC<br/>
                                14-30 rue de Mantes<br/>
                                Colombes 92700<br/>
                                France<br/><br/>
                                THE IFC is a company registered in France (890 872 245 R.C.S. Nanterre)
                                <br/><br/>
                                The EU Commission provides a platform for online dispute resolution at{" "}
                                <a
                                    className={styles.a}
                                    href="https://ec.europa.eu/consumers/odr"
                                >
                                    https://ec.europa.eu/consumers/odr
                                </a>
                                . Please note that THE IFC is not obliged to participate in such dispute
                                resolution.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
