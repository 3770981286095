import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

// Functions
import { fetchData } from '../../utils/fetchData'

// Styles
import styles from './index.module.scss'

// Components
import { SvgSprite } from '../SvgSprite/SvgSprite'
import { Form } from '../Form'
import { Loader } from '../Loader'
import { Img } from '../Img'
import { CompetitionItem } from '../ExactCompetitionUI/CompetitionItem'
import cn from 'classnames'
import { useMobile } from '../../hooks/useMobile'
import debounce from 'lodash.debounce'

import { useTranslation } from 'react-i18next'

export const SearchBar = () => {
    const isMobile = useMobile(401)
    const {t} = useTranslation()

    const [searchValue, setSearchValue] = useState('')
    const [teams, setTeams] = useState([])
    const [organizations, setOrganizations] = useState([])
    const [loading, setLoading] = useState(false)

    const onChange = e => setSearchValue(e?.target?.value)
    const debounceChange = debounce(onChange, 300)

    const limit = 10;

    const searchItems = () => {
        if (searchValue) {
            setLoading(true);
            fetchData(`/api/v3/teams?name=${searchValue}&active=true&limit=${limit}`)
            .then((response) => {
                setTeams(response.teams)
            })
            fetchData(`/api/v3/organizations?name=${searchValue}&active=true&limit=${limit}`)
            .then((response) => {
                setOrganizations(response.organizations)
            })
            setLoading(false)
        } else {
            setTeams([]);
            setOrganizations([]);
        }
    }

    useEffect(() => {
        if (!loading) searchItems()
    }, [searchValue])

    return (
        <div className={styles.teamsSearch}>
            <div className={cn(styles.teamsSearchTop)}>
                <Form className={styles.teamsSearchInpWrap} noStyles onSubmit={searchItems}>
                    <input
                        className={styles.teamsSearchInp}
                        type='text'
                        placeholder={t("search")}
                        onChange={debounceChange}
                    />
                    <button className={styles.teamsSearchBtn} type={'submit'}>
                        <SvgSprite spriteID={'search'}/>
                    </button>
                </Form>
            </div>
            {
                !loading ? (
                    <div className={cn(styles.teamsContent)}>
                        <ul className={styles.teamsContentList}>
                            <div className={styles.title} style={{marginTop: '10px'}}>{t("teams")}</div>
                            {teams.length > 0 ? 
                                teams.map(team => (
                                    <li className={styles.teamsContentItem} key={team.id}>
                                        <Link to={`/teams/${team.id}`} className={styles.teamsItemWrap}>
                                            <Img
                                                className={styles.teamsImg}
                                                src={team.picture}
                                                alt={'team'}
                                            />
                                            <CompetitionItem
                                                text={isMobile ? team.name.length > 8 ? team.name.slice(0, 5) + '...' : team.name : team.name}
                                                name
                                                auto
                                            />
                                        </Link>
                                    </li>
                                )) : (<div className={'empty-block'}>{t("noResults")}</div>)
                            }
                            <div className={styles.title} style={{marginTop: '30px'}}>{t("organizations")}</div>
                            {organizations.length > 0 ?
                                organizations.map(organization => (
                                    <li className={styles.teamsContentItem} key={organization.id}>
                                        <Link to={`/organizations/${organization.id}`} className={styles.teamsItemWrap}>
                                            <Img
                                                className={styles.teamsImg}
                                                src={organization.picture}
                                                alt={'team'}
                                            />
                                            <CompetitionItem
                                                text={isMobile ? organization.name.length > 8 ? organization.name.slice(0, 5) + '...' : organization.name : organization.name}
                                                name
                                                auto
                                            />
                                        </Link>
                                    </li>
                                )) : (<div className={'empty-block'}>{t("noResults")}</div>)
                            }
                        </ul>
                    </div>
                ) : <Loader/>
            }
        </div>
    )
}
