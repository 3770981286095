import React, { useEffect } from 'react'
import { v4 as uuid } from 'uuid'
import axios from "axios"

// Hooks
import { useAuth } from './core/hooks/useAuth'

// Components
import { MainRoutes } from './routes/MainRoutes'
import { Notifications } from './core/ui/Notifications'
import { AuthRoutes } from './routes/AuthRoutes'
import { useDispatch, useSelector } from 'react-redux'
import { addNotification, removeNotification } from './store/slices/notificationsSlice'
import { signIn, signOut } from './store/slices/userSlice'
import api from "./core/utils/api";
import { deleteCookie, setCookie } from './core/utils/cookie'
import { useNavigate } from 'react-router-dom'
import { setOrgs } from './store/slices/orgsSlice'
import { setTeams } from './store/slices/teamsSlice'

export const App = () => {
    const isAuth = useSelector(state => state.user.isAuth)
    const {checkIsAuth, isChecked} = useAuth()
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        checkIsAuth();
        api.configureApi({
            errorHandler: (error) => {
              let msg;
              console.log(error)
              if (!error.response) msg = "Something went wrong";
              else if (typeof error.response.data.message === "object")
                msg =
                  error.response.data.message[
                    Object.keys(error.response.data.message)[0]
                  ];
              else msg = error.response.data.message ?? "Something went wrong";
              const errorID = uuid()
              dispatch(addNotification({id: errorID, type: 'error', text: msg}))
              setTimeout(() => {
                dispatch(removeNotification({id: errorID}))
              }, 3000)
            },
            unauthorizedErrorHandler: () => {
                axios.defaults.headers.common.Authorization = "";
                deleteCookie("csrf_token");
                deleteCookie("refresh_token");
                dispatch(signOut());
                dispatch(setTeams([]))
                dispatch(setOrgs([]))
                const errorID = uuid()
                dispatch(addNotification({id: errorID, type: 'error', text: 'Session expired'}))
                setTimeout(() => {
                    dispatch(removeNotification({id: errorID}))
                }, 3000)
            },
            onUpdateCredentials: (credentials) => {
                axios.defaults.headers.common.Authorization = `Bearer ${credentials.accessToken}`;
                setCookie("refresh_token", credentials.refreshToken);
                setCookie("csrf_token", credentials.csrfToken);
                dispatch(signIn(credentials.accessToken));
            },
            navigate: navigate
        });
    }, [])


    return isChecked && (
        <>
            {isAuth ? <MainRoutes/> : <AuthRoutes/>}
            <Notifications/>
        </>
    )
}
