import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import 'react-datepicker/dist/react-datepicker.css'
import './assets/styles/datepicker.scss'
import './assets/styles/swiper.scss'
import './assets/styles/general.scss'
import { App } from './App'
import { BrowserRouter as Router, useLocation } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from './store'
import "./services/i18n";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { AliveScope } from 'react-activation'
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
let app;
let analytics;
if (process.env.REACT_APP_API_KEY) {
    app = initializeApp(firebaseConfig);
    analytics = getAnalytics(app);
}

function FirebaseAnalytics() {
    let location = useLocation();
    useEffect(() => {
        const page_path = location.pathname + location.search;
        if (analytics) {
            logEvent(analytics, 'screen_view', {
                firebase_screen: page_path,
            });
        }
    }, [location]);
    return null;
}

if(process.env.REACT_APP_SENTRY_URL)
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_URL,
        integrations: [new BrowserTracing()],
    
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <Provider store={store}>
        <Router>
            <FirebaseAnalytics />
            <AliveScope>
                <App/>
            </AliveScope>
        </Router>
    </Provider>
)
