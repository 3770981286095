import React from 'react'

// Styles
import styles from './index.module.scss'

// Components
import { Img } from '../../Img'
import { CompetitionItem } from '../CompetitionItem'
import { useTranslation } from 'react-i18next'
import { SvgSprite } from '../../SvgSprite/SvgSprite'

export const CompetitionPlayersStandard = ({data, stats}) => {
    const {t} = useTranslation()

    return data.length > 0 ? (
        <ul className={styles.matchPlayersList}>
            {
                data.map((player, idx) => {
                    let stat_keys = ['man_of_the_match', 'rating', 'goals', 'assists', 'passes', 'interceptions', 'tackles', 'saves', 'clean_sheet'];
                    const stat = stats.find(stat => stat.player.id === player.id);
                    return (
                    <li className={styles.matchPlayersItem} key={player.id}>
                        <div className={styles.matchPlayersItemWrap}>
                            <Img
                                className={styles.matchPlayersItemImg}
                                src={player.user.picture}
                                alt={'player'}
                            />
                            <CompetitionItem
                                text={player.user.pseudo || player.user.full_name}
                                name
                                auto
                            />
                            {stats.find(stat => stat.status === "pending" && stat.player.id === player.id) && (
                                <SvgSprite className={styles.pending} spriteID={'clock'}/>
                            )}
                        </div>
                        <div className={styles.matchPlayersItemBox}>
                            {stat_keys.map((key) => (
                                <CompetitionItem
                                    key={key}
                                    title={idx === 0 && key}
                                    text={(stat && stat[key]) || '-'}
                                />
                            ))}
                        </div>
                    </li>
                )})
            }
        </ul>
    ) : <div className={'empty-block'}>{t('noExistingPlayers')}</div>
}
