import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Utils
import { deleteCookie } from '../../utils/cookie'

// Slices
import { setLoadedData } from '../../../store/slices/loadedDataSlice'

// Styles
import styles from './index.module.scss'

// Components
import { HeaderMenuItem } from './HeaderMenuItem'
import { Btn } from '../Btn'
import { HeaderSessions } from './HeaderSessions'

import { useTranslation } from 'react-i18next'
import { fetchData } from '../../utils/fetchData'
import { signOut } from '../../../store/slices/userSlice'
import { setTeams } from '../../../store/slices/teamsSlice'
import { setOrgs } from '../../../store/slices/orgsSlice'
import axios from 'axios'

export const HeaderMenu = ({setSessions, sessions, openPopup}) => {
    const dispatch = useDispatch()
    const userInfo = useSelector(state => state.user.userInfo)

    const {t} = useTranslation()
    const logout = async () => {
        await fetchData(`/auth/v3/logout`, {
            method: 'DELETE',
        })

        axios.defaults.headers.common.Authorization = "";
        deleteCookie("csrf_token");
        deleteCookie("refresh_token");
        dispatch(signOut());
        dispatch(setTeams([]))
        dispatch(setOrgs([]))
        dispatch(setLoadedData(false))
    }

    return (
        <div className={styles.headerMenu}>
            <ul className={styles.headerMenuList}>
                <HeaderMenuItem icon={'user'} title={`${userInfo.first_name} ${userInfo.last_name}`} user={userInfo}/>
                <HeaderMenuItem icon={'settings'} title={t('helpAndSupport')}>
                    <a className={styles.headerMenuSubItem} href={'/terms-of-service'} rel={'noreferrer'}
                       target={'_blank'}>
                        {t('termsOfUse')}
                    </a>
                    <a className={styles.headerMenuSubItem} href={'/privacy-policy'}
                       rel={'noreferrer'} target={'_blank'}>
                        {t('privacyPolicy')}
                    </a>
                    <a className={styles.headerMenuSubItem} href='mailto:contact@the-ifc.fr'>
                        {t('contactUs')}
                    </a>
                </HeaderMenuItem>
                <HeaderMenuItem icon={'secure'} title={t('security')}>
                    <Btn className={styles.headerMenuSubItem} onClick={openPopup}>
                        {t('changePassword')}
                    </Btn>
                    <HeaderSessions setSessions={setSessions} sessions={sessions}/>
                </HeaderMenuItem>
            </ul>
            <Btn className={styles.headerMenuLogout} type={'button'} onClick={logout}>
                {t('logout')}
            </Btn>
        </div>
    )
}
