import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isAuth: false,
    userInfo: {},
    isAdmin: false,
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserInfo(state, action) {
            state.userInfo = action.payload
            state.isAdmin = action.payload.admin
        },
        setIsAuth(state, action) {
            state.isAuth = action.payload
        },
        signIn(state, action) {
            state.isAuth = true
            state.token = action.payload
        },
        signOut(state) {
            state.isAuth = false;
            state.userInfo = {};
            state.isAdmin = false;
        }
    }
})

export default userSlice.reducer
export const { setUserInfo, setIsAuth, signIn, signOut } = userSlice.actions
