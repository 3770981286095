const formatISO = (date) => {
  return date.toISOString().slice(0, 16);
};

const formatDate = (date) => {
  const month = `${date.getMonth() + 1 < 10 ? "0" : ""}${date.getMonth() + 1}`;
  const day = `${date.getDate() < 10 ? "0" : ""}${date.getDate()}`;
  return `${day}/${month}`;
};

const formatDateDayMonthYear = (date) => {
  const day = `${date.getDate() < 10 ? "0" : ""}${date.getDate()}`;
  const month = `${date.getMonth() + 1 < 10 ? "0" : ""}${date.getMonth() + 1}`;
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const formatTime = (time) => {
  let hours = time.getHours();
  hours = `${hours < 10 ? "0" : ""}${hours}`;
  const minutes = `${time.getMinutes() < 10 ? "0" : ""}${time.getMinutes()}`;
  return `${hours}h${minutes}`;
};

const formatDateMonthYear = (date) => {
  const month = `${date.getMonth() + 1 < 10 ? "0" : ""}${date.getMonth() + 1}`;
  const year = date.getFullYear();
  return `${month}/${year}`;
};

export {
  formatDate,
  formatDateDayMonthYear,
  formatTime,
  formatISO,
  formatDateMonthYear,
};