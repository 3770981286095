import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import cn from 'classnames'

// Styles
import styles from './index.module.scss'

// Components
import { Img } from '../../Img'
import { SvgSprite } from '../../SvgSprite/SvgSprite'

import { useTranslation } from 'react-i18next'
import { Btn } from '../../Btn'

import platform1 from "../../../../assets/images/platform1.svg";
import platform4 from "../../../../assets/images/platform4.svg";
import platform2 from "../../../../assets/images/platform2.svg";
import platform3 from "../../../../assets/images/platform3.svg";

export const CompetitionInfo = ({competition}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()

        const getPlatformImg = platform => {
        switch (platform) {
            case 'ps4':
                return platform1
            case 'ps5':
                return platform4
            case 'xbox-s':
                return platform2
            case 'xbox-one':
                return platform2
            case 'pc':
                return platform3
            default:
                return platform1
        }
    }
    const getPlatformText = platform => {
        switch (platform) {
            case 'ps4':
                return "PS4"
            case 'ps5':
                return "PS5"
            case 'xbox-s':
                return "Xbox S"
            case 'xbox-one':
                return "Xbox One"
            case 'pc':
                return "PC"
            default:
                return "PS4"
        }
    }

    return (
        <div className={styles.competition}>
            <div className={cn(styles.competitionInner, styles.relative)}>
                <Btn className={cn(styles.mainInfoBack,styles.marginReturn)} onClick={() => navigate(`/dashboard`)}>
                    <SvgSprite spriteID={'arrowNext'} /> 
                </Btn>
                <div className={styles.competitionImgWrap}>
                    <Img
                        className={styles.competitionImg}
                        src={competition.picture}
                        alt={'competition photo'}
                    />
                </div>
                <div className={styles.competitionContent}>
                    <div className={styles.competitionName}>
                        {competition.name}
                    </div>
                    <div className={styles.competitionOrganizerWrap}>
                        {t('organizedBy')} :
                        <Link to={`/organizations/${competition.organizer.id}`} className={styles.competitionOrganizer}>
                            <Img
                                className={styles.competitionOrganizerImg}
                                src={competition.organizer.picture}
                                alt={'organizer photo'}
                            />
                            {competition.organizer.name}
                        </Link>
                    </div>
                    <div className={styles.competitionMore}>
                        {
                            competition.current_round && (
                                <div className={cn(styles.competitionMoreItem, styles.competitionMoreItemCalendar)}>
                                    <SvgSprite spriteID={'calendar'} />
                                    {competition.current_round.name}
                                </div>
                            )
                        }
                        <div className={styles.competitionMoreItem}>
                            <SvgSprite spriteID={'members'} />
                            {competition.registered_participants} {competition.mode === 'solo' ? t('Joueurs') : t('teams')}
                        </div>
                    </div>
                    <div className={styles.mainInfoMoreItem}>
                        <div className={cn(styles.mainInfoMoreItemWrap, {
                            [styles.widthPs5Div] : competition.platform === 'ps5'
                        })}>
                            <Img
                                className={cn(styles.mainInfoMoreItemImg, {
                                    [styles.widthPs5Img] : competition.platform === 'ps5'
                                })}
                                src={getPlatformImg(competition.platform)}
                                alt='platform'
                            />
                        </div>
                        <span>{t(getPlatformText(competition.platform))}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
