import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

// Styles
import styles from './index.module.scss'

// Functions
import { checkErrors } from '../../functions/checkErrors'

// Hooks
import { useInput } from '../../hooks/useInput'

// Components
import { PageWrapper } from '../../ui/PageWrapper'
import { Form } from '../../ui/Form'
import { InputCode } from '../../ui/InputCode'
import { t } from 'i18next'
import { fetchData } from '../../utils/fetchData'
import { useDispatch } from 'react-redux'
import { signIn } from '../../../store/slices/userSlice'

export const VerifyEmail = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch();

    const inputCode = useInput('')

    const [errors, setErrors] = useState({})

    const submitCode = async () => {
        const inputsInfo = [
            {el: inputCode, errorName: 'code', errorText: 'Enter code'},
        ]

        
        if (await checkErrors(setErrors, inputsInfo)) return

        try {
            await fetchData(`/api/v3/profile/verify-email`, {
                method: 'PATCH',
                data: {
                    code: +inputCode.value,
                }
            })
            dispatch(signIn());
            navigate('/dashboard')
        } catch(err) {
            console.log(err);
        }
    }

    const resendCode = async()=>{
        await fetch(`${process.env.REACT_APP_API_HOST}/auth/v3/email/get-code?email=${location.state.email}`, {
            method: 'POST',
            body: JSON.stringify({}),
            headers: {
                'Content-Type': 'application/json',
            }
        })
    }

    return (
        <PageWrapper auth>
            <Form className={styles.forgotForm} onSubmit={submitCode}>

                <InputCode
                    length={6}
                    id={'code'}
                    error={errors.code}
                    onComplete={code => {
                        inputCode.value = code
                        submitCode()
                    }}
                />
                <div className={styles.forgotFormMessage}>
                    {t('enterCodeReceived')}
                </div>

                <div className={styles.registerForgotWrap}>
                    <button className={styles.registerForgot} onClick={()=>resendCode()}>
                        {t('IDidntReceivecCode')}
                    </button>
                </div>
            </Form>
        </PageWrapper>
    )
}
