import cn from 'classnames'
import React from 'react'
import {Link} from 'react-router-dom'
import {useMobile} from '../../hooks/useMobile'
import {SvgSprite} from '../../ui/SvgSprite/SvgSprite'
// Styles
import styles from './index.module.scss'
import logo from '../../../assets/images/logo.png'

export const Trademarks = () => {
    const isTablet = useMobile(1300)
    return (
        <div className={styles.pageWrapper}>
            <div className={cn({
                [styles.pageWrapperContent]: !isTablet,
                [styles.pageWrapperMobile]: isTablet
            })}>
                {!isTablet && (
                    <div className={styles.img}>
                        <img
                            className={styles.banner}
                            src={logo}
                            alt="Ifc Banner"
                        />
                    </div>
                )}
                <div className={cn(styles.text, {
                    [styles.textMobile]: isTablet,
                })}>
                    <div className={styles.header}>
                        <Link to={'/auth/login'} className={styles.matchArrowLink}>
                            <SvgSprite spriteID={'leftArrow'}/>
                        </Link>
                        Trademarks Guidelines
                    </div>
                    <div className={styles.body}>
                        <div className={styles.containerContent}>
                            <p className={styles.parag}>Last udpated: April 10, 2023</p>
                            <p className={styles.parag}>
                                Download our assets from our{" "}
                                <a
                                    className={styles.a}
                                    href="https://drive.google.com/file/d/1ZNEgmOL4S7r9vSJa-m8x49Wkcv8i09Dv/view?usp=sharing"
                                >
                                    asset page
                                </a>
                                .
                            </p>

                            <p className={styles.parag}>
                                We support and encourage ideas, services, tools and other creative works
                                that use and supplement THE IFC Brand Assets (meaning THE IFC
                                trademarks, logos, trade dress, web pages, copyrighted designs, original
                                content, or other brand assets), but we’re required to protect our
                                reputation and brand identity.
                            </p>
                            <p className={styles.parag}>
                                If there is express language on this page stating that you can use
                                particular THE IFC Brand Assets, advance written permission is not
                                necessary. By using THE IFC Brand Assets you agree to follow these
                                guidelines, THE IFC Terms of Service, and all other THE IFC rules and
                                policies, and, in situations where THE IFC provided express permission,
                                any other terms associated with that permission. THE IFC reserves the
                                right to cancel, modify, or change any permissive uses under these
                                guidelines at any time at its sole discretion.
                            </p>
                            <h1 className={styles.headerI}>Questions</h1>
                            <div className={styles.list}>
                                <p className={styles.parag}>
                                    &#x2022; If you have any questions regarding these guidelines, please
                                    contact{" "}
                                    <a
                                        className={styles.a}
                                        href="mailto:contact@the-ifc.com"
                                    >
                                        contact@the-ifc.com
                                    </a>
                                    .
                                </p>
                                <p className={styles.parag}>
                                    &#x2022; If you are a licensee of a THE IFC Brand Asset, please
                                    consult your agreement as it may contain special terms or terms
                                    different than our general guidelines. You are required to follow your
                                    agreement regardless of the terms below.
                                </p>
                                <p className={styles.parag}>
                                    &#x2022; If you are a THE IFC Partner and have a question about using
                                    THE IFC Brand Assets under your agreement, please contact your THE IFC
                                    business partner.
                                </p>
                                <p className={styles.parag}>
                                    &#x2022; If you’ve found a website that uses a THE IFC Brand Asset
                                    inappropriately, we’d like to hear about it, please report to{" "}
                                    <a
                                        className={styles.a}
                                        href="mailto:contact@the-ifc.com"
                                    >
                                        contact@the-ifc.com
                                    </a>
                                    .
                                </p>
                            </div>

                            <h1 className={styles.headerI}>General Usage Guidelines</h1>
                            <p className={styles.parag}>
                                Do use THE IFC Brand Assets to refer to THE IFC, our services and
                                websites, our apps and anything else we offer.
                            </p>
                            <p className={styles.parag}>
                                Do use THE IFC trademarks only as an adjective and never as a noun or
                                verb.
                            </p>
                            <p className={styles.parag}>
                                Do provide a reference to THE IFC as the source whenever and wherever
                                possible, or to make a point about compatibility (“Compatible with THE
                                IFC”).
                            </p>
                            <p className={styles.parag}>
                                Don’t modify or alter THE IFC Brand Assets in any way, such as changing
                                the color or design of our logos, app icons, or screen shots.
                            </p>
                            <p className={styles.parag}>
                                Don’t incorporate THE IFC’s Brand Assets, or variants, in domain names,
                                social media accounts, corporate names, app names, or as a part of any
                                on-line or in person event (including community events or meet-ups),
                                unless we have given express permission.
                            </p>
                            <p className={styles.parag}>
                                Don’t combine THE IFC Brand Assets as part of a name of a product or
                                service.
                            </p>
                            <p className={styles.parag}>
                                Don’t use THE IFC Brand Assets in a way that suggests an affiliation,
                                partnership, sponsorship or endorsement by or with THE IFC.
                            </p>
                            <p className={styles.parag}>
                                Don’t include THE IFC Brand Assets in editorial content that suggests
                                it’s been authored by, or represents the views or opinions of, THE IFC
                                or any THE IFC personnel or affiliate.
                            </p>
                            <p className={styles.parag}>
                                Don’t present THE IFC Brand Assets in a manner that is misleading,
                                defamatory, libelous, obscene, infringing or otherwise in a manner that
                                violates the spirit of THE IFC’s Community Guidelines.
                            </p>
                            <p className={styles.parag}>
                                Don’t use THE IFC Brand Assets on material that infringes the trademark,
                                copyright or any other rights of any third party.
                            </p>
                            <p className={styles.parag}>
                                Don’t infringe, derogate, dilute, or impair the rights of THE IFC in the
                                THE IFC Brand Assets.
                            </p>

                            <h1 className={styles.headerI}>Specific Usage Guidelines</h1>
                            <h2 className={styles.headerII}>Logos</h2>
                            <p className={styles.parag}>
                                We generally do not approve the use of the THE IFC logos in third-party
                                marketing materials. This includes online and offline advertising
                                collateral such as:
                            </p>
                            <div className={styles.list}>
                                <p className={styles.parag}>
                                    &#x2022; Client and referral lists
                                </p>
                                <p className={styles.parag}>
                                    &#x2022; Sales presentations
                                </p>
                                <p className={styles.parag}>
                                    &#x2022; Print, broadcast, outdoor or online ads
                                </p>
                                <p className={styles.parag}>&#x2022; Product demos</p>
                                <p className={styles.parag}>&#x2022; Signage</p>
                                <p className={styles.parag}>
                                    &#x2022; Trade show booths
                                </p>
                                <p className={styles.parag}>&#x2022; Websites</p>
                            </div>
                            <h2 className={styles.headerII}>Apparel</h2>
                            <p className={styles.parag}>
                                You may not use any THE IFC Brand Assets on your apparel.
                            </p>
                            <h2 className={styles.headerII}>Merchandise</h2>
                            <p className={styles.parag}>
                                THE IFC doesn’t permit the use of our Brand Assets on merchandise or
                                manufactured items, this includes uploading images of such products on
                                3rd party printing or production sites. You must obtain written
                                permission from THE IFC for such items.
                            </p>
                            <h2 className={styles.headerII}>Business Cards</h2>
                            <p className={styles.parag}>
                                We do not allow uses of THE IFC Brand Assets on your business card to
                                indicate any employment or business relationship with THE IFC.
                            </p>
                            <h2 className={styles.headerII}>
                                User Names and Domain Names
                            </h2>
                            <p className={styles.parag}>
                                We do not allow use of THE IFC Brand Assets, in whole or in part, as a
                                domain name or user name. This prohibition extends to the name of a
                                business, product, or service.
                            </p>
                            <h2 className={styles.headerII}>
                                Commercials, TV programs, Films, or Articles
                            </h2>
                            <p className={styles.parag}>
                                If you are looking to use THE IFC Brand Assets as part of an article, in
                                a film, or within a broadcast program: contact us. We are receptive to
                                those requests. Please include the following information with your
                                request:
                            </p>
                            <div className={styles.list}>
                                <p className={styles.parag}>
                                    &#x2022; Description of how THE IFC Brand Assets will be used.
                                </p>
                                <p className={styles.parag}>
                                    &#x2022; Visual mock-ups or screenshots of how you intend to present
                                    THE IFC Brand Assets.
                                </p>
                                <p className={styles.parag}>
                                    &#x2022; Summary of the project or article.
                                </p>
                            </div>

                            <h1 className={styles.headerI}>Legal Language</h1>
                            <p className={styles.parag}>
                                THE IFC reserves the right to change the appearance of the THE IFC Brand
                                Assets at any time without notice. Any use of the THE IFC Brand Assets
                                shall inure to the benefit of THE IFC. By using the Brand Assets
                                pursuant to THE IFC’s approval, you acknowledge THE IFC’s ownership of
                                all Brand Assets and warrant that you will not take any action which is
                                inconsistent with THE IFC’s ownership. Strict compliance with these
                                guidelines is required at all times, and any use of the THE IFC Brand
                                Assets in violation of these guidelines will automatically terminate any
                                license related to your use of the THE IFC Brand Assets.
                            </p>
                            <p className={styles.parag}>
                                THE IFC and the THE IFC Logo are trademarks of THE IFC.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
