import React, { useEffect, useState } from 'react'

// Styles
import styles from './index.module.scss'
import { Btn } from '../Btn'
import { handleError } from '../../functions/handleError'
import { showSuccessMessage } from '../../functions/showSuccessMessage'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { fetchData } from '../../utils/fetchData'
import { Img } from '../Img'

export const MembersChangeJersey = ({closePopup, member, id, getTeam, team}) => {
    const dispatch = useDispatch()
    const [selectedFile, setSelectedFile] = useState(null);
    const [disabledBtn, setDisabledBtn] = useState(true)
    const {t} = useTranslation()

    const changeJersey = async () => {
        try {
            const formData = new FormData();
            if (selectedFile) formData.append('jersey', selectedFile);

            const  url = `/api/v3/${team ? 'teams' : 'organizations'}/${id}/${team ? 'players' : 'members'}/${member.id}`
            await fetchData(url, {
                method: 'PATCH',
                data: formData,
            })

            showSuccessMessage(dispatch, 'Successfully updated jersey')
            getTeam()
            closePopup()
        } catch (error) {
            handleError(dispatch, error)
        }
    }

    const onFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    useEffect(() => {
        setDisabledBtn(selectedFile === null);
    }, [selectedFile])

    return (
        <div className={styles.membersChange}>
            <div className={styles.membersChangeTop}>
                <button className={styles.membersChangeClose} type={'button'} onClick={closePopup}>
                    <span/>
                    <span/>
                </button>
                <div className={styles.membersChangeTitle}>
                    {t('changePosition')}
                </div>
            </div>
            <div className={styles.membersChangeContent}>
                <input type="file" onChange={onFileChange} style={{ display: 'initial' } }/>
                {selectedFile && (
                    <Img
                        className={styles.membersContentItemImg}
                        src={URL.createObjectURL(selectedFile)}
                        alt={'cover'}
                    />
                )}
                <div className={styles.btnsChn}>
                    <Btn className={styles.membersChangeSubmit} onClick={closePopup} disable={disabledBtn}>
                        {t('cancel')}
                    </Btn>
                    <Btn className={styles.membersChangeSubmit} onClick={changeJersey} disable={disabledBtn} red={!disabledBtn}>
                        {t('validate')}
                    </Btn>
                </div>
                
            </div>
        </div>
    )
}
