import axios from "axios";
import { getCookie } from "./cookie";

const Config = {
    apiUrl: `${process.env.REACT_APP_API_HOST}/api/v3`,
    apiV4Url: `${process.env.REACT_APP_API_HOST}/api/v4`,
    authUrl: `${process.env.REACT_APP_API_HOST}/auth/v3`,
};

axios.defaults.withCredentials = true;

let isRefreshing = false;
let failedQueue = [];

const processQueue = () => {
  failedQueue.forEach((request) => request.resolve());
  failedQueue = [];
};

function intercept(
  error,
  { errorHandler, unauthorizedErrorHandler, onUpdateCredentials, navigate }
) {
  if (
    error.response?.config?.url.includes(Config.apiUrl) ||
    error.response?.config?.url.includes(Config.apiV4Url)
  ) {
    if (error.response.status === 404) {
      navigate('/404')
    }
    if (error.response.status === 401) {
      const originalRequest = error.config;
      delete originalRequest.headers.Authorization;

      if (!originalRequest._retry) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then(() => {
              return axios(originalRequest);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        return getAccessToken()
          .then((credentials) => {
            onUpdateCredentials(credentials);
            processQueue();
            return axios(originalRequest);
          })
          .catch((err) => {
            failedQueue = [];
            if (err.response?.status === 401)
              logout().finally(() => unauthorizedErrorHandler());
            else if (err.response) errorHandler(err);
            else errorHandler("Something went wrong");
            return Promise.reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      }
    } else if (error.response) errorHandler(error);
    else errorHandler("Something went wrong");
  }

  return Promise.reject(error);
}

const configureApi = (config) =>
  axios.interceptors.response.use(
    (response) => response,
    (error) => intercept(error, config)
);

function getAccessToken() {
    return new Promise((resolve, reject) => {
        const csrfToken = getCookie("csrf_token");
        const refreshToken = getCookie("refresh_token");
        if (csrfToken && refreshToken) {
        axios
            .post(
            Config.authUrl + "/refresh-token",
            {},
            {
                "X-CSRF-TOKEN": csrfToken,
                headers: {
                Authorization: `Bearer ${refreshToken}`,
                },
            }
            )
            .then((response) => {
                resolve({
                    accessToken: response.data.access_token,
                    csrfToken: response.data.csrf_token,
                    refreshToken: response.data.refresh_token,
                });
            })
            .catch((e) => reject(e));
        } else {
            reject(Error("No token present in storage"));
        }
    })
}

function logout() {
    return new Promise((resolve, reject) => {
      axios
        .delete(Config.authUrl + "/logout")
        .then(() => {
          resolve();
        })
        .catch((e) => reject(e));
    });
}

export default { configureApi, getAccessToken, Config }