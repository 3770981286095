import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {v4 as uuid} from 'uuid'
import { useDispatch } from 'react-redux'
import cn from 'classnames'
import axios from "axios"

// Styles
import styles from './index.module.scss'

// Hooks
import { useInput } from '../../hooks/useInput'

// Functions
import { checkErrors } from '../../functions/checkErrors'

// Slices
import { addNotification } from '../../../store/slices/notificationsSlice'

// Utils
import { setCookie } from '../../utils/cookie'

// Functions
import { removeNotifTimeout } from '../../functions/removeNotifTimeout'

// Components
import { PageWrapper } from '../../ui/PageWrapper'
import { Form } from '../../ui/Form'
import { Input } from '../../ui/Input'
import { Btn } from '../../ui/Btn'
import { Loader } from '../../ui/Loader'
import { useTranslation } from 'react-i18next'
import { signIn } from '../../../store/slices/userSlice'

export const Login = () => {
    window.location.replace("about.the-ifc.fr")
    const dispatch = useDispatch()

    const inputEmail = useInput('')
    const inputPassword = useInput('')

    const {t} = useTranslation()

    const [errors, setErrors] = useState({})
    const [loader, setLoader] = useState(false)
    const loginUser = async () => {
        const inputsInfo = [
            {el: inputEmail, errorName: 'email', errorText: 'enterEmailInput'},
            {el: inputPassword, errorName: 'password', errorText: 'enterPassword'},
        ]

        if (await checkErrors(setErrors, inputsInfo)) return

        setLoader(true)
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_HOST}/auth/v3/email/login`, {
                email: inputEmail.value,
                password: inputPassword.value,
            })
            axios.defaults.headers.common.Authorization = `Bearer ${res.data.access_token}`;
            setCookie("refresh_token", res.data.refresh_token);
            setCookie("csrf_token", res.data.csrf_token);
            dispatch(signIn(res.data.access_token));    
        } catch (err) {
            console.log(err);
            const errorID = uuid()
            dispatch(addNotification({id: errorID, type: 'error', text: err.response.data.message}))
            dispatch(removeNotifTimeout(errorID, 3000))
        }
        setLoader(false)
    }

    return (
        <PageWrapper auth>
            <Form className={styles.loginForm} onSubmit={loginUser}>
                <div className={styles.loginFormTitle}>
                    {t('ConnectToIFC')}
                </div>
                <Input
                    type={'email'}
                    placeholder={'Email'}
                    id={'email'}
                    {...inputEmail}
                    error={t(errors.email)}
                />
                <Input
                    type={'password'}
                    placeholder={'Password'}
                    id={'password'}
                    {...inputPassword}
                    error={t(errors.password)}
                />
                <Btn type={'submit'} className={cn(styles.loginFormBtn,{
                    [styles.activeBtn] : loader
                })}>
                   {!loader? t('login') : <Loader loginLoader={true} />}
                </Btn>
                <div className={styles.loginForgotWrap}>
                    <Link className={styles.loginForgot} to={'/auth/forgot-password'} state={inputEmail.value}>
                        {t('forgotPassword')}
                    </Link>
                </div>
                <div className={styles.loginSignUp}>
                    <div className={styles.loginSignUpTitle}>
                        {t('noAccount')}
                    </div>
                    <Link to={'/auth/register'} className={styles.loginSignUpLink}>
                        {t('SignUp')}
                    </Link>
                </div>
            </Form>
        </PageWrapper>
    )
}
