import axios from 'axios'

export const fetchData = async (endpoint, ...args) => {
    return new Promise((resolve, reject) => {
        axios(`${process.env.REACT_APP_API_HOST}${endpoint}`, ...args)
        .then((response) => {
            resolve(response.data)
        })
        .catch((e) => reject(e))
    })
}
