import { fetchData } from "../utils/fetchData"

export const getSquadCompetitions = async (limit=10, offset, id, isTeam) => {
    let pastTour, liveTour, futureTour, pastLeague, liveLeague, futureLeague

    if (isTeam) {
        pastTour = await fetchData(`/api/v4/teams/${id}/tournaments?limit=${limit}${offset ? `&offset=${offset}` : ''}${(id && !isTeam) ? `&organizer_id=${id}` : ''}&status=past`)
        liveTour = await fetchData(`/api/v4/teams/${id}/tournaments?limit=${limit}${offset ? `&offset=${offset}` : ''}${(id && !isTeam) ? `&organizer_id=${id}` : ''}&status=live`)
        futureTour = await fetchData(`/api/v4/teams/${id}/tournaments?limit=${limit}${offset ? `&offset=${offset}` : ''}${(id && !isTeam) ? `&organizer_id=${id}` : ''}&status=future`)
        pastLeague = await fetchData(`/api/v4/teams/${id}/leagues?limit=${limit}${offset ? `&offset=${offset}` : ''}${(id && !isTeam) ? `&organizer_id=${id}` : ''}&status=past`)
        liveLeague = await fetchData(`/api/v4/teams/${id}/leagues?limit=${limit}${offset ? `&offset=${offset}` : ''}${(id && !isTeam) ? `&organizer_id=${id}` : ''}&status=live`)
        futureLeague = await fetchData(`/api/v4/teams/${id}/leagues?limit=${limit}${offset ? `&offset=${offset}` : ''}${(id && !isTeam) ? `&organizer_id=${id}` : ''}&status=future`)
    } else {
        pastTour = await fetchData(`/api/v4/competitions/squad/tournaments?limit=${limit}${offset ? `&offset=${offset}` : ''}${id ? `&organizer_id=${id}` : ''}&status=past`)
        liveTour = await fetchData(`/api/v4/competitions/squad/tournaments?limit=${limit}${offset ? `&offset=${offset}` : ''}${id ? `&organizer_id=${id}` : ''}&status=live`)
        futureTour = await fetchData(`/api/v4/competitions/squad/tournaments?limit=${limit}${offset ? `&offset=${offset}` : ''}${id ? `&organizer_id=${id}` : ''}&status=future`)
        pastLeague = await fetchData(`/api/v4/competitions/squad/leagues?limit=${limit}${offset ? `&offset=${offset}` : ''}${id ? `&organizer_id=${id}` : ''}&status=past`)
        liveLeague = await fetchData(`/api/v4/competitions/squad/leagues?limit=${limit}${offset ? `&offset=${offset}` : ''}${id ? `&organizer_id=${id}` : ''}&status=live`)
        futureLeague = await fetchData(`/api/v4/competitions/squad/leagues?limit=${limit}${offset ? `&offset=${offset}` : ''}${id ? `&organizer_id=${id}` : ''}&status=future`)
    }

    return [...pastTour.competitions,
        ...liveTour.competitions,
        ...futureTour.competitions,
        ...pastLeague.competitions,
        ...liveLeague.competitions,
        ...futureLeague.competitions]
}
