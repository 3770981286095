import { setUserInfo } from '../../store/slices/userSlice'
import { fetchData } from '../utils/fetchData'
import { getAnalytics, setUserId } from "firebase/analytics";

export const setUserInfoData = () => {
    return async dispatch => {
        const res = await fetchData(`/api/v3/profile`)
        if (process.env.REACT_APP_API_KEY) {
            const analytics = getAnalytics();
            setUserId(analytics, res.profile.id.toString());
        }
        dispatch(setUserInfo({admin: res.admin, ...res.profile}))
    }
}
